import React, { useEffect } from "react";
import { useState } from "react";
import defaultImg from "../../images/sm_album_art_placeholder.png";
import {
  BsArrowLeftShort,
  BsArrowRepeat,
  BsArrowRightShort,
  BsFillSkipEndFill,
  BsFillSkipForwardBtnFill,
  BsFillSkipStartFill,
  BsRepeat1,
  BsShuffle,
  BsSkipBackwardBtnFill,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { GiArtificialIntelligence } from "react-icons/gi";
import {
  FaPause,
  FaPlay,
  FaVolumeDown,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";
import { TbRepeatOff, TbArrowsShuffle } from "react-icons/tb";
import {
  MdExplicit,
  MdHighQuality,
  MdLibraryMusic,
  MdLyrics,
  MdOndemandVideo,
  MdOutlineHighQuality,
} from "react-icons/md";
import { RiPlayList2Fill, RiTimeLine, RiVideoFill } from "react-icons/ri";
import Marquee from "react-text-marquee";
import logo from "../../images/sonar_muse_logo_nav_invert2.png";
import invertLogo from "../../images/sonar_muse_logo_nav2.png";
import {
  convertDuration,
  getArtistsNames,
  getArtistsNames2,
  getPodcastersNames2,
  getSongTitle,
  getPodcastersNames,
  getObjectLength,
  convertTimeToSeconds,
  stringFormatter,
} from "../../utils";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MEDIA_SCREEN,
  MUSIC_PLAYING_FROM,
  PODCASTER_SEARCH_TYPES,
} from "../../utils/Enum";
import ImageUtils from "../../utils/ImageUtils";
import { AudioPlayerContainer } from "../AudioPlayer/AudioPlayerElements";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import ScrollToTop from "../ScrollToTop";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import ToggleButton from "../ToggleButton";
import {
  Audio,
  AudioPlayerControls,
  AudioPlayerControlsWrapper,
  AudioPlayerCoverArt,
  AudioPlayerCoverArtWrapper,
  AudioPlayerDetailsWrapper,
  AudioPlayerDurationTimerWrapper,
  AudioPlayerPlaybackControlsWrapper,
  AudioPlayerSlider,
  AudioPlayerSliderWrapper,
  AudioPlayerSongDetailsText,
  AudioPlayerSongDetailsWrapper,
  AudioPlayerTimerText,
  AudioPlayerVolumeSlider,
  AudioPlayerWrapper,
  PreviewBadge,
  VolumeControlsWrapper,
} from "./AudioPlayerElements";
import { useAlert } from "../Alert/AlertContext";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SkeletonIcons,
  SkeletonText,
  SkeletonTextEnd,
} from "../Skeleton/SkeletonElements";
import { Img } from "../ContentList/ContentListElements";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { Small } from "../QRCodeForm/QrCodeFormElements";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import {
  CloseButton,
  HeaderText,
  PlaylistContainer,
  PlaylistContainerWrapper,
  PlaylistWrapper,
  Text,
} from "../AudioPlayer/AudioPlayerPlaylist/AudioPlayerPlaylistElements";
import { Zoom } from "react-reveal";
import RecordPlayer from "../RecordPlayer";
import VideoPlayer from "../VideoPlayer";
import ContentWrapper from "../ContentWrapper";
import { CustomButton } from "../ButtonElement";
import { FormWrapper as FW } from "../CustomForm/CustomFormElements";
import LyricSynchronizer from "../LyricSynchronizer";
import { MusicLyricsWrapper } from "../MusicPage/MusicPageElements";
import { AudioPlayerQueueList } from "../ContentList/SongList";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import MusicInfo from "../MusicPage/MusicInfo";
import { useUser } from "../../hooks/useUser";
import { FormWrapper } from "../MusicUploadsForm/MusicUploadsFormElements";
import MenuOptions from "../MenuOptions";
import useMusic from "../../hooks/useMusic";
import useWindowDimensions from "../../hooks/useWindowDeimensions";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AudioPlayer = ({
  timeJump,
  currentSong,
  isPlaying,
  duration,
  currentTime,
  progressBar,
  audioPlayer,
  changeRange,
  setTimeJump,
  volume,
  isRepeat,
  calcTime,
  backThirty,
  forwardThirty,
  isPodcast,
  isShuffle,
  nextSong,
  handleToggle,
  prevSong,
  playlist,
  setPlaylist,
  togglePlayPause,
  toggleMute,
  toggleVolume,
  isOpen,
  closeViewer,
  isMute,
  handleEnd,
  openViewer,
  isQueueLoaded,
  currentNav,
  timeTravel,
  setCurrentSong,
  stopMainPlayer,
  onClose,
}) => {
  const {
    currentUser,
    getUserSubscription,
    isUserSubscribed,
    getUserLikes,
    userLikes,
  } = useAuth();
  const { defaultAlbumArt } = ImageUtils();
  const { unlikeSong, likeSong, unlikeEpisode, likeEpisode } = useMusic();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const { options } = MenuOptions();
  const { toggleModal, modalContent, openModal, closeModal } = useModal();
  const [isCountdown, setCoundown] = useState(false);
  const { getUserQueue } = useUser({});
  const [currentTab, setTab] = useState(currentNav ? currentNav : "default");
  const [isPlaylistLoaded, setPlaylistLoaded] = useState(false);
  const [playlistTab, setPlaylistTab] = useState(
    isUserPayingOut() ? "playlist" : "nfts"
  );
  const [quality, setQuality] = useState('standard')
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if(audioPlayer?.current){
      audioPlayer.current.currentTime = currentTime
      if(isPlaying){
        handleToggle('play')
      }
    }
  
    return () => {
     
    }
  }, [quality])

  useEffect(() => {
  
    if(!playlist[currentSong]?.lyrics){
      setTab('default')
    }
  
    return () => {
    }
  }, [currentSong, isOpen])
  
  

  /*  useEffect(() => {
    caches.keys().then(keys => {
      if(keys?.length > 0){

    
    caches.open('smMp').then((cache) => {
      return cache.match(playlist[currentSong]?.url, {}).then((res) => {
          console.log(res)
        if(res){
          var reader = res.body.getReader();
return reader.read().then(result => {
return result;
});
        }else{
          cache.add(playlist[currentSong]?.url)
          return false
        }
      }).then(data => {
        if(data){
          var blob = new Blob([data.value], { type: "audio/wav" });
var blobUrl = URL.createObjectURL(blob);
return blobUrl
        }else{
          console.log(false)
          cache.addAll(playlist.map(s => {
            console.log(s?.url)
            return s?.url
          })).then(() => {
            console.log(`added`)
          })
          return playlist[currentSong]?.url
        }
      })
      
    })}else{
      caches.open('smMp').then((cache) => {
      cache.addAll(playlist.map(s => {
        console.log(s?.url)
        return s?.url
      })).then(() => {
        console.log(`added`)
      })
      })
    }
  
  
  })
    return () => {}
  }, [currentSong])  */

  const option = async () => {
    openModal();
    modalContent(
      <OptionModal
        heading={
          playlist[currentSong]?.songName
            ? playlist[currentSong]?.songName
            : playlist[currentSong]?.episodeName
        }
      >
        <HoverOption
          onClick={() => {
            setTab("details");
            modalContent();
            closeModal();
          }}
        >
          View Details
        </HoverOption>
        {playlist[currentSong]?.lyrics && (
          <HoverOption
            onClick={() => {
              setTab("lyrics");
              modalContent();
              closeModal();
            }}
          >
            Lyrics
          </HoverOption>
        )}
        <HoverOption
          onClick={() => {
            setTab("queue");
            modalContent();
            closeModal();
          }}
        >
          Queue
        </HoverOption>
        {currentUser?.uid && (
          <HoverOption onClick={() => options(playlist[currentSong])}>
            More Options
          </HoverOption>
        )}
      </OptionModal>
    );
  };

  const validatePlay = async () => {
    let isPlay = false;
    try {
      if (isPlaying) {
        handleToggle("play-pause", !isPlaying);
        return;
      } else {
        if (!currentUser?.subscription) {
          await getUserSubscription();
        }
        isPlay = await isUserSubscribed();
        if (isPlay && isPlay !== "Network Error") {
          if(playlist[currentSong]?.isSubscription && currentUser?.artistSubscriptions?.length > 0 && playlist[currentSong].artists.filter(artist => currentUser?.artistSubscriptions?.some(a => 
            artist?.artistId === a)).length === 0){
              setPlaylist(
                playlist.filter(s => (!s?.isSubscription && ((s?.isPreview && s?.isPreRelease) || !s?.isPreRelease)) ||  s?.artists.filter(artist => currentUser?.artistSubscriptions?.some(a => 
                  artist?.artistId === a))).map((s) => {
                    if(s?.isSubscription){
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.SUBSCRIPTION,
                    id: s.songId,
                  },
                }}
                return s
              })
              );
              return handleToggle("play-pause", !isPlaying);
            }
            if(playlist[currentSong]?.isSubscription && !currentUser?.artistSubscriptions){
              setPlaylist(
                playlist.filter(s => (!s?.isSubscription && ((s?.isPreview && s?.isPreRelease) || !s?.isPreRelease))))
                nextSong()
                return handleToggle("play", !isPlaying);
            }
            return handleToggle("play", !isPlaying);
        }
        if (isPlay === "Network Error") {
          return handleToggle("play-pause", !isPlaying);
        } else {
          if(currentUser?.artistSubscriptions?.length > 0 && playlist.filter(s => s?.artists.filter(artist => currentUser?.artistSubscriptions?.some(a => 
            artist?.artistId === a))).length > 0){
              if (isQueueLoaded) {
                // filter songs that are purchased within song list
                setPlaylist(
                  playlist.filter(s => s?.artists.filter(artist => currentUser?.artistSubscriptions?.some(a => 
                    artist?.artistId === a))).map((s) => {
                  return {
                    ...s,
                    playingFrom: {
                      playingFrom: MUSIC_PLAYING_FROM.SUBSCRIPTION,
                      id: s.songId,
                    },
                  };
                })
                );
                handleToggle("play");
                return;
              }
            }
          if (
            (currentUser?.purchases?.songs?.length > 0 &&
              currentUser?.purchases?.songs.some(
                (s) => s?.songId === playlist[currentSong]?.songId
              )) ||
            (playlist[currentSong]?.album &&
              currentUser?.purchases?.albums?.length > 0 &&
              currentUser?.purchases?.albums.some(
                (a) => a?.albumId === playlist[currentSong]?.album?.albumId
              ))
          ) {
            if (isQueueLoaded) {
              // filter songs that are purchased within song list
              setPlaylist(
                playlist?.filter(
                  (song) =>
                    currentUser?.purchases?.songs.some(
                      (s) => s?.songId === song?.songId
                    ) ||
                    (song?.album &&
                      currentUser?.purchases?.albums?.length > 0 &&
                      currentUser?.purchases?.albums.some(
                        (a) => a?.albumId === song?.album?.albumId
                      ))
                ).map((s) => {
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                    id: s.songId,
                  },
                };
              })
              )
              handleToggle("play");
              return;
            }
          }
          openModal();
          modalContent(
            <>
              <StreamingSubscriptionModal
                onSuccess={() => {
                  getUserSubscription(currentUser?.uid);
                  handleToggle("play-pause", !isPlaying);
                  openModal();
                  return modalContent(
                    <OptionModal heading={"Subscription Enabled Sucessfully"}>
                      <HoverOption
                        onClick={() => {
                          closeModal();
                          modalContent();
                        }}
                      >
                        OK
                      </HoverOption>
                    </OptionModal>
                  );
                }}
                onFail={() => {
                  return modalContent(
                    <OptionModal heading={"Subscription Unsucessful"}>
                      <HoverOption
                        onClick={() => {
                          closeModal();
                          modalContent();
                        }}
                      >
                        OK
                      </HoverOption>
                    </OptionModal>
                  );
                }}
                cancel={() => {
                  closeModal();
                  modalContent();
                  return;
                }}
              />
            </>
          );
        }
      }
    } catch (err) {
      //console.log(err)
      if (isPlaying) {
        handleToggle("play-pause", !isPlaying);
        return;
      }
    }
  };

  const toggleLike = async () => {
    if (playlist[currentSong]?.songId) {
      console.log(
        userLikes
          ?.filter((like) => like.songId)
          .some((s) => s.songId === playlist[currentSong]?.songId)
      );
      userLikes
        ?.filter((like) => like.songId)
        ?.some((s) => s.songId === playlist[currentSong]?.songId)
        ? await unlikeSong(playlist[currentSong]?.songId)
        : await likeSong(playlist[currentSong]?.songId);
    }
    if (playlist[currentSong]?.episodeId) {
      userLikes
        ?.filter((like) => like.episodeId)
        ?.some((s) => s.episodeId === playlist[currentSong]?.episodeId)
        ? await unlikeEpisode(playlist[currentSong]?.episodeId)
        : await likeEpisode(playlist[currentSong]?.episodeId);
    }
    return await getUserLikes(currentUser?.uid);
  };
  const displayContent = () => {
    switch (currentTab) {
      case "lyrics":
        return (
          <>
            {playlist[currentSong]?.syncedLyrics ? (
              <LyricSynchronizer
                song={playlist[currentSong]}
                currentTime={audioPlayer?.current?.currentTime}
                togglePlayPause={togglePlayPause}
                timeTravel={timeTravel}
                isPlaying={isPlaying}
              />
            ) : (
              <MusicLyricsWrapper>
                {stringFormatter(playlist[currentSong]?.lyrics)}
              </MusicLyricsWrapper>
            )}
          </>
        );

      case "queue":
        return (
          <>
            <HeaderText>Queue</HeaderText>
            <PlaylistWrapper>
              <AudioPlayerQueueList
                content={playlist}
                onButtonClick={setCurrentSong}
                type={CONTENT_TYPES.SONG}
                displayFullList={true}
              />
            </PlaylistWrapper>
          </>
        );
      case "timestamps":
        return (
          <>
            <ContentWrapper>
              <FW>
                <HeaderText>Timestamps</HeaderText>
                {playlist[currentSong]?.timestamps.map((timestamp) => {
                  return (
                    <FormWrapper
                      style={{ alignContent: "left", justifyContent: "left" }}
                    >
                      <CustomButton
                        onClick={() => {
                          timeTravel(convertTimeToSeconds(timestamp?.time));
                        }}
                      >
                        [{timestamp?.time}]
                      </CustomButton>
                      - {timestamp?.label}
                    </FormWrapper>
                  );
                })}
              </FW>
            </ContentWrapper>
          </>
        );
      case "details":
        return (
          <ContentWrapper
            heading={
              playlist[currentSong]?.songName
                ? playlist[currentSong]?.songName
                : playlist[currentSong]?.episodeName
            }
          >
            {/*    <RecordPlayer
              onClick={validatePlay}
              stop={!isPlaying}
              albumArt={
                playlist[currentSong]?.albumArt
                ? playlist[currentSong]?.albumArt
                : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
              }
              alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
            /> */}

            {playlist?.length > 0 ? (
              <MusicInfo
                type={
                  playlist[currentSong]?.songId
                    ? CONTENT_TYPES.SONG
                    : CONTENT_TYPES.EPISODE
                }
                content={playlist[currentSong]}
              />
            ) : (
              "No Songs Available"
            )}
          </ContentWrapper>
        );
      case "video":
        return (
          <>
            <VideoPlayer
              stopMainPlayer={stopMainPlayer}
              isPlaying={isPlaying}
              content={playlist[currentSong]}
              validateSubscription={true}
            />
          </>
        );
      case "visuals":
        return (
          <PlaylistWrapper>
            {
              {
                /* <VideoPlayer/> */
              }
            }
          </PlaylistWrapper>
        );
      default:
        return (
          <>
            <RecordPlayer
              onClick={validatePlay}
              stop={!isPlaying}
              albumArt={
                playlist[currentSong]?.albumArt
                  ? playlist[currentSong]?.albumArt
                  : playlist[currentSong]?.thumbnail
                  ? playlist[currentSong]?.thumbnail
                  : defaultImg
              }
              alt={
                playlist[currentSong]?.songName
                  ? playlist[currentSong]?.songName
                  : playlist[currentSong]?.episodeName
              }
            />

            <AudioPlayerSongDetailsWrapper
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <HeaderText
                onClick={() => {
                  onClose();
                  if (playlist[currentSong]?.songId) {
                    goTo(`/song/${playlist[currentSong]?.songId}`);

                    return;
                  }
                  if (playlist[currentSong]?.episodeId) {
                    /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                    goTo(`/episode/${playlist[currentSong]?.episodeId}`);
                    return;
                  }
                }}
              >
                <>
                  {playlist[currentSong]?.songId &&
                    getSongTitle(playlist[currentSong])}

                  {playlist[currentSong]?.episodeId &&
                    playlist[currentSong]?.episodeName}
                </>
              </HeaderText>
              <Text
                onClick={
                  playlist[currentSong] &&
                  (playlist[currentSong]?.artists?.length > 0 ||
                    playlist[currentSong]?.podcasters?.length > 0)
                    ? () => {
                        onClose();
                        if (playlist[currentSong]?.songId) {
                          goTo(
                            `/artist/${playlist[currentSong]?.artists[0]?.artistId}`
                          );
                          return;
                        }
                        if (playlist[currentSong]?.episodeId) {
                          /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                          goTo(
                            `/podcaster/${playlist[currentSong]?.podcasters[0]?.podcasterId}`
                          );
                          return;
                        }
                      }
                    : () => {
                        onClose();
                      }
                }
              >
                <div>
                  {playlist[currentSong]?.explicit && <MdExplicit />}
                  {playlist[currentSong]?.video?.url && <RiVideoFill />}
                  {playlist[currentSong]?.songId && (
                    <>
                      {getArtistsNames2(
                        playlist[currentSong]?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )?.length > 30 ? (
                        <Marquee
                          text={getArtistsNames(
                            playlist[currentSong]?.artists,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        />
                      ) : (
                        getArtistsNames(
                          playlist[currentSong]?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        )
                      )}{" "}
                      {playlist[currentSong]?.album &&
                        ` — ${playlist[currentSong]?.album?.albumName}`}
                    </>
                  )}
                  {playlist[currentSong]?.episodeId &&
                    (getPodcastersNames2(
                      playlist[currentSong]?.podcasters,
                      PODCASTER_SEARCH_TYPES.NAME
                    )?.length > 30 ? (
                      <Marquee
                        text={getPodcastersNames(
                          playlist[currentSong]?.podcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        )}
                      />
                    ) : (
                      getPodcastersNames(
                        playlist[currentSong]?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )
                    ))}
                </div>
              </Text>
            </AudioPlayerSongDetailsWrapper>
          </>
        );
    }
  };

  return (
    <>
      <Audio
        ref={audioPlayer}
        src={
          quality === 'standard' ? playlist[currentSong]?.mp3Url
            ? playlist[currentSong]?.mp3Url
            : playlist[currentSong]?.url : playlist[currentSong]?.url
        }
        loop={isRepeat === 2}
        preload={"auto"}
        volume={volume}
        muted={isMute || volume === 0}
        onEnded={handleEnd}
      />
      {isOpen && (
        <>
          <PlaylistContainer
            isOpen={isOpen}
            src={playlist[currentSong]?.visualizer?.gifUrl ? playlist[currentSong]?.visualizer?.gifUrl : playlist[currentSong]?.albumArt
              ? playlist[currentSong]?.albumArt
              : playlist[currentSong]?.thumbnail
              ? playlist[currentSong]?.thumbnail
              : defaultAlbumArt}
          >
            <PlaylistContainerWrapper visualizer={playlist[currentSong]?.visualizer?.gifUrl && true}>
              <CloseButton onClick={onClose} />
              <Zoom>
                <AudioPlayerDetailsWrapper>
                  {displayContent()}
                  <AudioPlayerControlsWrapper style={{ padding: " 0 3%" }}>
                    {" "}
                    <VolumeControlsWrapper>
                      <AudioPlayerControls
                        style={{ fontSize: "1.4rem" }}
                        onClick={toggleMute}
                      >
                        {isMute || volume === 0 ? (
                          <FaVolumeMute />
                        ) : volume * 100 < 50 ? (
                          <FaVolumeDown />
                        ) : (
                          <FaVolumeUp />
                        )}
                      </AudioPlayerControls>
                      <AudioPlayerVolumeSlider
                        type="range"
                        volume={isMute ? "0%" : `${volume * 59}px`}
                        value={isMute ? 0 : volume * 100}
                        onChange={toggleVolume}
                      />
                    </VolumeControlsWrapper>
                    <AudioPlayerControlsWrapper
                      style={{
                        display: "flex",
                        padding: "2%",
                        width: "100%",
                        justifyContent: "flex-end",
                        gap: "3%",
                      }}
                    >
                      {currentTab !== "default" && (
                        <AudioPlayerControls
                          style={{ fontSize: "1.6rem" }}
                          onClick={() => setTab("default")}
                        >
                          <MdLibraryMusic />
                        </AudioPlayerControls>
                      )}
                        {playlist[currentSong]?.songId && playlist[currentSong]?.mp3Url && (
                        <AudioPlayerControls
                          style={{ fontSize: "1.6rem" }}
                          onClick={() => quality === 'standard' ? setQuality("hq") : setQuality("standard")}
                        >
                          {quality === 'standard' ? <MdOutlineHighQuality /> : <MdHighQuality />}
                        </AudioPlayerControls>
                      )}
                      {playlist[currentSong]?.lyrics && (
                        <AudioPlayerControls
                          style={{ fontSize: "1.6rem" }}
                          onClick={() =>
                            currentTab === "lyrics"
                              ? setTab("default")
                              : setTab("lyrics")
                          }
                        >
                          <MdLyrics />
                        </AudioPlayerControls>
                      )}
                      {playlist[currentSong]?.timestamps && (
                        <AudioPlayerControls
                          style={{ fontSize: "1.6rem" }}
                          onClick={() =>
                            currentTab === "timestamps"
                              ? setTab("default")
                              : setTab("timestamps")
                          }
                        >
                          <RiTimeLine />
                        </AudioPlayerControls>
                      )}
                      {
                        <AudioPlayerControls
                          style={{ fontSize: "2rem" }}
                          onClick={() => {
                            toggleLike();
                          }}
                        >
                          {currentUser?.uid &&
                            (!playlist[currentSong]?.isPreRelease ||
                              (playlist[currentSong]?.isPreRelease &&
                                playlist[currentSong]?.isPreview)) && (
                              <>
                                {userLikes?.length > 0 &&
                                (userLikes
                                  ?.filter((like) => like.songId)
                                  .some(
                                    (s) =>
                                      s.songId === playlist[currentSong]?.songId
                                  ) ||
                                  userLikes
                                    ?.filter((like) => like.episodeId)
                                    .some(
                                      (s) =>
                                        s.episodeId ===
                                        playlist[currentSong]?.episodeId
                                    )) ? (
                                  <FcLike />
                                ) : (
                                  <FcLikePlaceholder />
                                )}
                              </>
                            )}
                        </AudioPlayerControls>
                      }
                      <AudioPlayerControls
                        style={{ fontSize: "2rem" }}
                        onClick={() =>
                          currentTab === "queue"
                            ? setTab("default")
                            : setTab("queue")
                        }
                      >
                        <RiPlayList2Fill />
                      </AudioPlayerControls>
                      <AudioPlayerControls
                        style={{ fontSize: "2rem" }}
                        onClick={() => playlist?.length > 0 && option()}
                      >
                        <BsThreeDotsVertical />
                      </AudioPlayerControls>
                    </AudioPlayerControlsWrapper>
                  </AudioPlayerControlsWrapper>
                  <div
                    style={{
                      display: "flex",
                      padding: "3%",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <AudioPlayerSliderWrapper>
                      <AudioPlayerSlider
                        progressWidth={`${(currentTime / duration) * 100}%`}
                        type={"range"}
                        ref={progressBar}
                        value={currentTime}
                        max={duration}
                        onChange={changeRange}
                      />
                    </AudioPlayerSliderWrapper>
                    <AudioPlayerDurationTimerWrapper
                      style={{
                        fontSize: "0.8rem",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setCoundown(!isCountdown)}
                    >
                      <AudioPlayerTimerText
                        onClick={() => setCoundown(!isCountdown)}
                      >
                        {isPlaying && calcTime(currentTime)}
                      </AudioPlayerTimerText>
                      <AudioPlayerTimerText
                        onClick={() => setCoundown(!isCountdown)}
                      >
                        {isPlaying && !isNaN(duration)
                          ? isCountdown
                            ? convertDuration(
                                playlist[currentSong]?.duration
                                  ? playlist[currentSong]?.duration -
                                      currentTime
                                  : duration - currentTime
                              )
                            : convertDuration(
                                playlist[currentSong]?.duration
                                  ? playlist[currentSong]?.duration
                                  : duration
                              )
                          : "0:00"}
                      </AudioPlayerTimerText>
                    </AudioPlayerDurationTimerWrapper>
                  </div>
                  <AudioPlayerControlsWrapper
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <AudioPlayerControls
                      style={{ fontSize: "1.2rem" }}
                      isActive={isRepeat !== false}
                      onClick={() => {
                        handleToggle("repeat", isRepeat);
                      }}
                    >
                      {!isRepeat && <TbRepeatOff />}{" "}
                      {isRepeat === 1 && <BsArrowRepeat />}
                      {isRepeat === 2 && <BsRepeat1 />}
                    </AudioPlayerControls>
                    <AudioPlayerPlaybackControlsWrapper
                      isPodcast={playlist[currentSong]?.episodeId}
                      style={{
                        display: "flex",
                        width: "60%",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <AudioPlayerControls
                        style={{ fontSize: "3rem" }}
                        onClick={() => {
                          handleToggle("prev", true);
                        }}
                      >
                        <BsFillSkipStartFill />
                      </AudioPlayerControls>
                      {playlist[currentSong]?.episodeId && (
                        <AudioPlayerControls
                          onClick={() => {
                            backThirty();
                          }}
                        >
                          <BsSkipBackwardBtnFill />
                        </AudioPlayerControls>
                      )}
                      <AudioPlayerControls
                        style={{ fontSize: "3rem" }}
                        onClick={() => {
                          validatePlay();
                        }}
                      >
                        {isPlaying ? <FaPause /> : <FaPlay />}
                      </AudioPlayerControls>
                      {playlist[currentSong]?.episodeId && (
                        <AudioPlayerControls
                          style={{ fontSize: "3rem" }}
                          onClick={() => {
                            forwardThirty();
                          }}
                        >
                          <BsFillSkipForwardBtnFill />
                        </AudioPlayerControls>
                      )}
                      <AudioPlayerControls
                        style={{ fontSize: "3rem" }}
                        onClick={() => {
                          handleToggle("next", true);
                        }}
                      >
                        <BsFillSkipEndFill />
                      </AudioPlayerControls>
                    </AudioPlayerPlaybackControlsWrapper>
                    <AudioPlayerControls
                      style={{ fontSize: "1.2rem" }}
                      isActive={isShuffle}
                      onClick={() => {
                        handleToggle("shuffle", !isShuffle);
                      }}
                    >
                      <TbArrowsShuffle />
                    </AudioPlayerControls>
                  </AudioPlayerControlsWrapper>
                </AudioPlayerDetailsWrapper>
              </Zoom>
            </PlaylistContainerWrapper>
          </PlaylistContainer>
        </>
      )}

      {!isOpen && (
        <AudioPlayerContainer isLoaded={true} isOpen={isOpen}>
          <AudioPlayerControlsWrapper>
            <AudioPlayerControls
              style={{ fontSize: "1.4rem" }}
              onClick={openViewer}
            >
              <RiPlayList2Fill />
            </AudioPlayerControls>
            {width >= MEDIA_SCREEN.TABLETS && (
              <VolumeControlsWrapper>
                <AudioPlayerControls onClick={toggleMute}>
                  {isMute || volume === 0 ? (
                    <FaVolumeMute />
                  ) : volume * 100 < 50 ? (
                    <FaVolumeDown />
                  ) : (
                    <FaVolumeUp />
                  )}
                </AudioPlayerControls>
                <AudioPlayerVolumeSlider
                  type="range"
                  volume={isMute ? "0%" : `${volume * 58.5}px`}
                  value={isMute ? 0 : volume * 100}
                  onChange={toggleVolume}
                />
              </VolumeControlsWrapper>
            )}
          </AudioPlayerControlsWrapper>
          <AudioPlayerWrapper>
            <AudioPlayerCoverArtWrapper>
              <AudioPlayerCoverArt
                onClick={openViewer}
                src={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail
                    ? playlist[currentSong]?.thumbnail
                    : defaultAlbumArt
                }
                alt={""}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
              />
            </AudioPlayerCoverArtWrapper>
            <AudioPlayerDetailsWrapper>
              <AudioPlayerSongDetailsWrapper>
                <AudioPlayerSongDetailsText
                  onClick={() => {
                    closeViewer();
                    if (playlist[currentSong]?.songId) {
                      goTo(`/song/${playlist[currentSong]?.songId}`);

                      return;
                    }
                    if (playlist[currentSong]?.episodeId) {
                      /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                      goTo(`/episode/${playlist[currentSong]?.episodeId}`);
                      return;
                    }
                  }}
                >
                  <>
                    {playlist[currentSong]?.songId &&
                      (getSongTitle(playlist[currentSong])?.length > 30 ? (
                        <Marquee text={getSongTitle(playlist[currentSong])} />
                      ) : (
                        getSongTitle(playlist[currentSong])
                      ))}

                    {playlist[currentSong]?.episodeId &&
                      (playlist[currentSong]?.episodeName?.length > 30 ? (
                        <Marquee text={playlist[currentSong]?.episodeName} />
                      ) : (
                        playlist[currentSong]?.episodeName
                      ))}
                  </>
                </AudioPlayerSongDetailsText>
                <AudioPlayerSongDetailsText
                  onClick={
                    playlist[currentSong] &&
                    (playlist[currentSong]?.artists?.length > 0 ||
                      playlist[currentSong]?.podcasters?.length > 0)
                      ? () => {
                          if (playlist[currentSong]?.songId) {
                            goTo(
                              `/artist/${playlist[currentSong]?.artists[0]?.artistId}`
                            );
                            return;
                          }
                          if (playlist[currentSong]?.episodeId) {
                            /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                            goTo(
                              `/podcaster/${playlist[currentSong]?.podcasters[0]?.podcasterId}`
                            );
                            return;
                          }
                        }
                      : {}
                  }
                >
                  <small onClick={closeViewer}>
                    {playlist[currentSong]?.explicit && <MdExplicit />}
                    {playlist[currentSong]?.video?.url && <RiVideoFill />}
                    {playlist[currentSong]?.songId &&
                      (getArtistsNames2(
                        playlist[currentSong]?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )?.length > 30 ? (
                        <Marquee
                          text={getArtistsNames(
                            playlist[currentSong]?.artists,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        />
                      ) : (
                        getArtistsNames(
                          playlist[currentSong]?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        )
                      ))}
                    {playlist[currentSong]?.episodeId &&
                      (getPodcastersNames2(
                        playlist[currentSong]?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )?.length > 30 ? (
                        <Marquee
                          text={getPodcastersNames(
                            playlist[currentSong]?.podcasters,
                            PODCASTER_SEARCH_TYPES.NAME
                          )}
                        />
                      ) : (
                        getPodcastersNames(
                          playlist[currentSong]?.podcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        )
                      ))}
                  </small>
                </AudioPlayerSongDetailsText>
                <AudioPlayerDurationTimerWrapper
                  onClick={() => setCoundown(!isCountdown)}
                >
                  <AudioPlayerTimerText
                    onClick={() => setCoundown(!isCountdown)}
                  >
                    {isPlaying && calcTime(currentTime)}
                  </AudioPlayerTimerText>
                  <AudioPlayerTimerText
                    onClick={() => setCoundown(!isCountdown)}
                  >
                    {isPlaying && !isNaN(duration)
                      ? isCountdown
                        ? convertDuration(
                            playlist[currentSong]?.duration
                              ? playlist[currentSong]?.duration - currentTime
                              : duration - currentTime
                          )
                        : convertDuration(
                            playlist[currentSong]?.duration
                              ? playlist[currentSong]?.duration
                              : duration
                          )
                      : "0:00"}
                  </AudioPlayerTimerText>
                </AudioPlayerDurationTimerWrapper>
              </AudioPlayerSongDetailsWrapper>
              <AudioPlayerSliderWrapper>
                <AudioPlayerSlider
                  progressWidth={`${(currentTime / duration) * 100}%`}
                  type={"range"}
                  ref={progressBar}
                  value={currentTime}
                  max={duration}
                  onChange={changeRange}
                />
              </AudioPlayerSliderWrapper>
            </AudioPlayerDetailsWrapper>
          </AudioPlayerWrapper>
          <AudioPlayerControlsWrapper>
            {width >= MEDIA_SCREEN.TABLETS && (
              <AudioPlayerControls
                isActive={isRepeat !== false}
                onClick={() => {
                  handleToggle("repeat", isRepeat);
                }}
              >
                {!isRepeat && <TbRepeatOff />}{" "}
                {isRepeat === 1 && <BsArrowRepeat />}
                {isRepeat === 2 && <BsRepeat1 />}
              </AudioPlayerControls>
            )}
            <AudioPlayerPlaybackControlsWrapper
              isPodcast={playlist[currentSong]?.episodeId}
            >
              <AudioPlayerControls
                style={{ fontSize: "1.4rem" }}
                onClick={() => {
                  handleToggle("prev", true);
                }}
              >
                <BsFillSkipStartFill />
              </AudioPlayerControls>
              {playlist[currentSong]?.episodeId &&
                width >= MEDIA_SCREEN.TABLETS && (
                  <AudioPlayerControls
                    onClick={() => {
                      backThirty();
                    }}
                  >
                    <BsSkipBackwardBtnFill />
                  </AudioPlayerControls>
                )}
              <AudioPlayerControls
                style={{ fontSize: "1.4rem" }}
                onClick={() => {
                  validatePlay();
                }}
              >
                {isPlaying ? <FaPause /> : <FaPlay />}
              </AudioPlayerControls>
              {playlist[currentSong]?.episodeId &&
                width >= MEDIA_SCREEN.TABLETS && (
                  <AudioPlayerControls
                    style={{ fontSize: "1.4rem" }}
                    onClick={() => {
                      forwardThirty();
                    }}
                  >
                    <BsFillSkipForwardBtnFill />
                  </AudioPlayerControls>
                )}
              <AudioPlayerControls
                style={{ fontSize: "1.4rem" }}
                onClick={() => {
                  handleToggle("next", true);
                }}
              >
                <BsFillSkipEndFill />
              </AudioPlayerControls>
            </AudioPlayerPlaybackControlsWrapper>
            {width >= MEDIA_SCREEN.TABLETS && (
              <AudioPlayerControls
                isActive={isShuffle}
                onClick={() => {
                  handleToggle("shuffle", !isShuffle);
                }}
              >
                <TbArrowsShuffle />
              </AudioPlayerControls>
            )}
          </AudioPlayerControlsWrapper>
        </AudioPlayerContainer>
      )}
    </>
  );
};

export const AudioPlayerSkeleton = () => {
  return (
    <AudioPlayerContainer>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls>
          <SkeletonIcons />
        </AudioPlayerControls>
        <VolumeControlsWrapper>
          <SkeletonIcons />
        </VolumeControlsWrapper>
      </AudioPlayerControlsWrapper>
      <AudioPlayerWrapper>
        <AudioPlayerCoverArtWrapper>
          <AudioPlayerCoverArt
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </AudioPlayerCoverArtWrapper>
        <AudioPlayerDetailsWrapper>
          <AudioPlayerSongDetailsWrapper>
            <AudioPlayerSongDetailsText>
              <>
                <SkeletonText />
              </>
            </AudioPlayerSongDetailsText>
            <AudioPlayerSongDetailsText>
              <small>
                <SkeletonTextEnd />
              </small>
            </AudioPlayerSongDetailsText>
            <AudioPlayerDurationTimerWrapper>
              <AudioPlayerTimerText></AudioPlayerTimerText>
              <AudioPlayerTimerText>0:00</AudioPlayerTimerText>
            </AudioPlayerDurationTimerWrapper>
          </AudioPlayerSongDetailsWrapper>
          <AudioPlayerSliderWrapper>
            <AudioPlayerSlider type={"range"} value={0} readOnly={true} />
          </AudioPlayerSliderWrapper>
        </AudioPlayerDetailsWrapper>
      </AudioPlayerWrapper>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls isActive={true}>
          <SkeletonIcons />
        </AudioPlayerControls>
        <AudioPlayerPlaybackControlsWrapper>
          <AudioPlayerControls>
            <SkeletonIcons />
          </AudioPlayerControls>

          <AudioPlayerControls>
            <SkeletonIcons />
          </AudioPlayerControls>
          <AudioPlayerControls>
            <SkeletonIcons />
          </AudioPlayerControls>
        </AudioPlayerPlaybackControlsWrapper>
        <AudioPlayerControls>
          <SkeletonIcons />
        </AudioPlayerControls>
      </AudioPlayerControlsWrapper>
    </AudioPlayerContainer>
  );
};

export const AudioPreviewPlayer = ({
  timeJump,
  currentPreview,
  isPreviewPlaying,
  previewDuration,
  currentPreviewTime,
  progressBar,
  audioPlayer,
  changeRange,
  stopMainPlayer,
  setTimeJump,
  volume,
  isRepeat,
  calcTime,
  backThirty,
  forwardThirty,
  isPodcast,
  isShuffle,
  previewNext,
  handlePreviewToggle,

  previewPlaylist,

  togglePreviewPlay,
  toggleMute,
  toggleVolume,

  handleEnd,
  isMute,
  openViewer,
}) => {
  const { defaultAlbumArt } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { theme } = useWebsiteTheme();
  const [isCountdown, setCoundown] = useState();
  const [isOpen, setOpen] = useState(false)
  useEffect(() => {
    return () => {};
  });

  const chapters = [
    {
      start: 0,
      end: 0,
    },
    {
      start: 0,
      end: 0,
    },
  ];

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const close = () => {
    setOpen(false)
  }

  return (
    <>{<AudioPlayerContainer isLoaded={true}>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls>
          <RiPlayList2Fill />
        </AudioPlayerControls>
        <VolumeControlsWrapper>
          <AudioPlayerControls onClick={toggleMute}>
            {isMute || volume === 0 ? (
              <FaVolumeMute />
            ) : volume * 100 < 50 ? (
              <FaVolumeDown />
            ) : (
              <FaVolumeUp />
            )}
          </AudioPlayerControls>
          <AudioPlayerVolumeSlider
            type="range"
            volume={isMute ? "0%" : `${volume * 59}px`}
            value={isMute ? 0 : volume * 100}
            onChange={toggleVolume}
          />
        </VolumeControlsWrapper>
      </AudioPlayerControlsWrapper>
      <AudioPlayerWrapper>
        <Audio
          ref={audioPlayer}
          src={`${
            previewPlaylist[currentPreview]?.mp3Url
              ? previewPlaylist[currentPreview]?.mp3Url
              : previewPlaylist[currentPreview]?.url
          }#t=${
            previewPlaylist[currentPreview]?.duration - 30 > 0
              ? previewPlaylist[currentPreview]?.duration - 30
              : 0
          }`}
          loop={isRepeat === 2}
          preload={"auto"}
          volume={volume}
          muted={isMute || volume === 0}
          onEnded={handleEnd}
        />
        <AudioPlayerCoverArtWrapper>
          <AudioPlayerCoverArt
            onClick={openViewer}
            src={
              previewPlaylist[currentPreview]?.albumArt
                ? previewPlaylist[currentPreview]?.albumArt
                : previewPlaylist[currentPreview]?.thumbnail
                ? previewPlaylist[currentPreview]?.thumbnail
                : defaultAlbumArt
            }
            alt={""}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </AudioPlayerCoverArtWrapper>
        <AudioPlayerDetailsWrapper>
          <AudioPlayerSongDetailsWrapper>
            <AudioPlayerSongDetailsText
              onClick={() => {
                if (previewPlaylist[currentPreview]?.songId) {
                  goTo(`/song/${previewPlaylist[currentPreview]?.songId}`);

                  return;
                }
                if (previewPlaylist[currentPreview]?.episodeId) {
                  /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                  goTo(
                    `/episode/${previewPlaylist[currentPreview]?.episodeId}`
                  );
                  return;
                }
              }}
            >
              <>
                {previewPlaylist[currentPreview]?.songId &&
                  (getSongTitle(previewPlaylist[currentPreview])?.length >
                  30 ? (
                    <Marquee
                      text={getSongTitle(previewPlaylist[currentPreview])}
                    />
                  ) : (
                    getSongTitle(previewPlaylist[currentPreview])
                  ))}

                {previewPlaylist[currentPreview]?.episodeId &&
                  (previewPlaylist[currentPreview]?.episodeName?.length > 30 ? (
                    <Marquee
                      text={previewPlaylist[currentPreview]?.episodeName}
                    />
                  ) : (
                    previewPlaylist[currentPreview]?.episodeName
                  ))}
              </>
            </AudioPlayerSongDetailsText>
            <AudioPlayerSongDetailsText
              onClick={
                previewPlaylist?.length > 0 &&
                (previewPlaylist[currentPreview]?.artists?.length > 0 ||
                  previewPlaylist[currentPreview]?.podcasters?.length > 0)
                  ? () => {
                      if (previewPlaylist[currentPreview]?.songId) {
                        goTo(
                          `/artist/${previewPlaylist[currentPreview]?.artists[0]?.artistId}`
                        );
                        return;
                      }
                      if (previewPlaylist[currentPreview]?.episodeId) {
                        /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                        goTo(
                          `/podcaster/${previewPlaylist[currentPreview]?.podcasters[0]?.podcasterId}`
                        );
                        return;
                      }
                    }
                  : () => {}
              }
            >
              {previewPlaylist?.length > 0 && (
                <small>
                  {previewPlaylist[currentPreview]?.explicit && <MdExplicit />}
                  {previewPlaylist[currentPreview]?.video?.url && (
                    <RiVideoFill />
                  )}
                  {previewPlaylist[currentPreview]?.songId &&
                    (getArtistsNames2(
                      previewPlaylist[currentPreview]?.artists,
                      ARTIST_SEARCH_TYPES.NAME
                    )?.length > 30 ? (
                      <Marquee
                        text={getArtistsNames(
                          previewPlaylist[currentPreview]?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      />
                    ) : (
                      getArtistsNames(
                        previewPlaylist[currentPreview]?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )
                    ))}
                  {previewPlaylist[currentPreview]?.episodeId &&
                    (getPodcastersNames2(
                      previewPlaylist[currentPreview]?.podcasters,
                      PODCASTER_SEARCH_TYPES.NAME
                    )?.length > 30 ? (
                      <Marquee
                        text={getPodcastersNames(
                          previewPlaylist[currentPreview]?.podcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        )}
                      />
                    ) : (
                      getPodcastersNames(
                        previewPlaylist[currentPreview]?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )
                    ))}
                </small>
              )}
              {previewPlaylist?.length === 0 && (
                <Small
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "10px",
                    opacity: "0.8",
                  }}
                >
                  <img
                    alt={"Sonar Muse"}
                    style={{
                      display: "flex",
                      height: "15px",
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                    src={theme === "dark" ? invertLogo : logo}
                  />
                </Small>
              )}
            </AudioPlayerSongDetailsText>
            <AudioPlayerDurationTimerWrapper
            >
              {/* <AudioPlayerTimerText>
                {isPreviewPlaying && calcTime((currentPreviewTime - 
                      (previewPlaylist[currentPreview]?.duration - 30 > 0
                        ? previewPlaylist[currentPreview]?.duration - 30
                        : 0))) }
              </AudioPlayerTimerText>
              <AudioPlayerTimerText >
                { isPreviewPlaying && !isNaN(previewDuration)
                  ? isCountdown
                    ? convertDuration(previewDuration - currentPreviewTime )
                    : convertDuration(previewDuration 
                    ) 
                  : ""}
              </AudioPlayerTimerText> */}
            </AudioPlayerDurationTimerWrapper>
            {previewPlaylist?.length > 0 && (
              <PreviewBadge>Preview</PreviewBadge>
            )}
          </AudioPlayerSongDetailsWrapper>
          {/*   <AudioPlayerSliderWrapper>
        <AudioPlayerSlider
            readOnly={true}
            progressWidth={`${(currentPreviewTime / previewDuration) * 100}%`}
            bufferWidth={`${((currentPreviewTime / previewDuration)* 100) }%`}
            type={"range"}
            ref={progressBar}
          />
        </AudioPlayerSliderWrapper>*/}
        </AudioPlayerDetailsWrapper>
      </AudioPlayerWrapper>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls
          isActive={isRepeat !== false}
          onClick={() => {
            handlePreviewToggle("repeat", isRepeat);
          }}
        >
          {!isRepeat && <TbRepeatOff />} {isRepeat === 1 && <BsArrowRepeat />}
          {isRepeat === 2 && <BsRepeat1 />}
        </AudioPlayerControls>
        <AudioPlayerPlaybackControlsWrapper
          isPodcast={previewPlaylist[currentPreview]?.episodeId}
        >
          <AudioPlayerControls
            style={{ fontSize: "1.4rem" }}
            onClick={() => {
              handlePreviewToggle("prev", true);
            }}
          >
            <BsFillSkipStartFill />
          </AudioPlayerControls>
          {previewPlaylist[currentPreview]?.episodeId && (
            <AudioPlayerControls
              onClick={() => {
                backThirty();
              }}
            >
              <BsSkipBackwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls
            style={{ fontSize: "1.4rem" }}
            onClick={() => {
              togglePreviewPlay();
            }}
          >
            {isPreviewPlaying ? <FaPause /> : <FaPlay />}
          </AudioPlayerControls>
          {previewPlaylist[currentPreview]?.episodeId && (
            <AudioPlayerControls
              style={{ fontSize: "1.4rem" }}
              onClick={() => {
                forwardThirty();
              }}
            >
              <BsFillSkipForwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls
            style={{ fontSize: "1.4rem" }}
            onClick={() => {
              handlePreviewToggle("next");
            }}
          >
            <BsFillSkipEndFill />
          </AudioPlayerControls>
        </AudioPlayerPlaybackControlsWrapper>
        <AudioPlayerControls
          isActive={isShuffle}
          onClick={() => {
            handlePreviewToggle("shuffle", !isShuffle);
          }}
        >
          <TbArrowsShuffle />
        </AudioPlayerControls>
      </AudioPlayerControlsWrapper>
    </AudioPlayerContainer>}</>
  );
};

export default AudioPlayer;
