import React, { useEffect, useState } from "react";
import ContentWrapper from "../ContentWrapper";
import { Slider } from "../Slider";
import {
  SearchBar,
  SearchBarWrapper,
  SearchButton,
} from "../Searchbar/SearchbarElements";
import {
  FilterButton,
  FilterH1,
  MusicStoreHeader,
  MusicStoreHeaderContainer,
  SearchContainer,
  StoreContainer,
  StoreFilter,
  StoreFiltersContainer,
  StoreWrapper,
  Title,
} from "./MusicStoreElements";
import { FaSearch } from "react-icons/fa";
import useMusic from "../../hooks/useMusic";
import { useNavigate } from "react-router-dom";
import { MUSIC_PURCHASE_OPTIONS, SLIDER_TYPES } from "../../utils/Enum";
import { Header } from "../Header";
import ScrollToTop from "../ScrollToTop";
import { getObjectLength } from "../../utils";
import { shuffleSongs, updateWebPageIcon } from "../../utils/MusicUtils";
import { getTopDigitalPurchasesUpdates, getTopNFTokenPurchasesUpdates } from "../../utils/dbUtils/purchasesUtils";

export const MusicStore = () => {
  const {
    getDownloadableSongs,
    getDownloadableAlbums,
    getMusicRoyalties,
    getSubscriptionSongs,
    getNFTokenCollections,
    getDownloadableSongsByName,
    getDownloadableAlbumsByName,
    getNFTokenCollectionsByName,
    getSubscriptionSongsByName,
    getSubscriptionAlbumsByName,
    getTopDigitalPurchases,
    getTopNFTokenPurchases,
    getSwerlVouchers
  } = useMusic({});
  const navigate  = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [searchItems, setSearchItems] = useState({});
  const {goTo} = ScrollToTop()
  const [isLoaded, setLoaded] = useState(false);
  const [isCollectionsLoaded, setCollectionsLoaded] = useState(false);
  const [isSongsLoaded, setSongsLoaded] = useState(false);
  const [isAlbumsLoaded, setAlbumsLoaded] = useState(false);
  const [isRoyaltiesLoaded, setRoyaltiesLoaded] = useState(false);
  const [isTrendCollectionsLoaded, setTrendCollectionsLoaded] = useState(false);
  const [isTrendDigitalsLoaded, setTrendDigitalsLoaded] = useState(false);
  const [items, setItems] = useState({});
  const [images, setImages] = useState([])
  const [category, setCategory] = useState("category");
  const [item, setItem] = useState("items");
  const [sort, setSort] = useState("sort");
  const [selectedItems, setSelected] = useState({});
  const [songs, setSongs] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [collections, setCollections] = useState([]);
  const [topDigitalPurchases, setTopDigitalPurchases] = useState({})
  const [topCollectionPurchases, setTopCollectionPurchases] = useState({})
  const [musicRoyalties, setMusicRoyalties] = useState([])
  //const [swerlVouchers, setSwerlVouchers] = useState([])
  //const [isSwerlLoaded, setSwerlLoaded] = useState(false)

  useEffect(() => {
    updateWebPageIcon('https://sonarmuse.org/favicon.ico')
    return () => {}
  },[])

  useEffect(() => {
    getMusicRoyalties(0).then(data => {
      if (data?.length > 0) {
        setItems(prev => {
          return {...prev, royalties: data?.royalties}
        })
       
        setMusicRoyalties(data);
      }
      setRoyaltiesLoaded(true)
    }).catch(err => {
      setRoyaltiesLoaded(true)
    })
    getDownloadableSongs(0).then(data => {
      if (data?.length > 0) {
        setItems(prev => {
          return {...prev, songs: data?.songs?.filter(s => s?.songId)}
        })
        setSongs(data?.songs?.filter(s => s?.songId));
      }
      setSongsLoaded(true)
    }).catch(err => {
      setSongsLoaded(true)
    })
    getDownloadableAlbums(0).then(data => {
      if(data?.length > 0) {
        setItems(prev => {
          return {...prev, albums: data?.albums}
        })
        setAlbums(data.albums);
      }
      setAlbumsLoaded(true)
    }).catch(err => {
      setAlbumsLoaded(true)
    })
    getNFTokenCollections(0).then(data => {
      if (data?.length > 0) {
        setCollections(data);
        setItems(prev => {
          return {...prev, collections: data.collections}
        })
      }
      setCollectionsLoaded(true)
    }).catch(err => {
      setCollectionsLoaded(true)
    })
    getTopDigitalPurchases().then(data => {
      if(data){
        setTopDigitalPurchases(data)
        getTopDigitalPurchasesUpdates(setTopDigitalPurchases)
      }
      setTrendDigitalsLoaded(true)
    }).catch(err => {
      setTrendDigitalsLoaded(true)
    })
    getTopNFTokenPurchases().then(data => {
      if(data){
        setTopCollectionPurchases(data)
        getTopNFTokenPurchasesUpdates(setTopCollectionPurchases)
      }
      setTrendDigitalsLoaded(true)
    }).catch(err => {
      setTrendCollectionsLoaded(true)
    })
    /* getSwerlVouchers().then(vouchers => {
      if(vouchers){
      setSwerlVouchers(vouchers)
    }
    setSwerlLoaded(true)
    }).catch(err => {
      setSwerlLoaded(true)
    }) */
    return () => {}
  }, []);

 useEffect(() => {
  if(isAlbumsLoaded && isCollectionsLoaded && isSongsLoaded){
    setItems({
      collections: collections,
      albums: albums,
      songs: songs
    })
  }

 }, [])

  function compare(a, b) {
    if (a.price < b.price) {
      return -1;
    }
    if (a.price > b.price) {
      return 1;
    }
    return 0;
  }

  const handleSearch = async (e) => {
    let content = {collections: [], albums: [], songs: []};
    setSearchItems({});
    setSearchValue(e.target.value);
    setLoaded(false);
    let search = await Promise.all([
      getDownloadableSongsByName(e.target.value),
      getDownloadableAlbumsByName(e.target.value),
      getNFTokenCollectionsByName(e.target.value),
      getSubscriptionSongsByName(e.target.value),
      getSubscriptionAlbumsByName(e.target.value)
      //getRoyaltyTokensByName(e.target.value)
    ]).then((data) => {
      console.log(data)
      if (data[0]?.length > 0) {
        content = { ...content, songs: [...content?.songs,
          ...data[0].filter(s => s?.songId)] };
      }
      if (data[1]?.length > 0) {
        content = {
          ...content,
          albums: data[1]?.filter(a => a?.albumId),
        };
      }
      if (data[2]?.length > 0) {
        content = {
          ...content,
          collections: data[2],
        };
      }
      if (data[3]?.length > 0) {
        
        content = {
          ...content,
          songs: [...content?.songs, ...data[3].filter(s => s?.songId)],
        };
      }
      if (data[4]?.length > 0) {
        content = {
          ...content,
          albums: [...content?.albums, ...data[4].filter(a => a?.albumId)],
        };
      }
      return content
    }).catch(err => {
    });
    setSearchItems(search);
    return;
  };

  const handleInput = (e, type) => {
    let selected =
      selectedItems && Object.keys(selectedItems).length > 0
        ? selectedItems
        : items;
        console.log(items)
    switch (type) {
      case "categories":
        setSelected({});
        switch (e.target.value) {
          case "all":
            setSort("all");
            setSelected({});
            break;
          case "downloads":
            setSort("all");
            setSelected({
              albums: selected?.albums
                ? selected?.albums?.filter((item) => item?.isDownloadable)
                : items?.albums?.filter((item) => item?.isDownloadable),
              songs: selected?.songs
                ? selected?.songs?.filter((item) => item?.isDownloadable)
                : items?.songs?.filter((item) => item?.isDownloadable),
            });
            break;
          case "nfts":
            setSort("nfts");
            selected = {
              collections: selected?.collections
                ? selected?.collections
                : items?.collections,
            };
            setSelected(selected);
            break;
            case "royalties":
                  setItem("royalties");
                  selected = {
                    royalties: items?.royalties?.filter((item) => item?.royaltyId),

                  };
                  setSelected(selected);
                  break;
          case "subscription":
            setSort("all");
            selected = {
              albums: selected?.albums
                ? selected?.albums?.filter((item) => item?.isSubscription)
                : items?.albums?.filter((item) => item?.isSubscription),
              songs: selected?.songs
                ? selected?.songs?.filter((item) => item?.isSubscription)
                : items?.songs?.filter((item) => item?.isSubscription),
            };
            setSelected(selected);
            break;
          default:
            setSort("sort");
            setSelected({});
            break;
        }

        break;
      case "items":
        setSelected({});
        switch (e.target.value) {
          case "albums":
            setItem("albums");
            selected = {
              albums: items?.albums?.filter((item) => item?.albumId),
              collections: items?.collections?.filter(
                (item) => item?.collectionType === "album"
              ),
              royalties: items?.royalties?.filter((item) => item?.type === "album"),
            };
            setSelected(selected);
            break;
          case "singles":
            setItem("singles");
            selected = {
              songs: items?.songs?.filter((item) => item.songId),
              collections: items?.collections?.filter(
                (item) => item?.collectionType === "single"
              ),
              royalties: items?.royalties?.filter((item) => item?.type === "song"),

            };
            setSelected(selected);
            break;
          case "custom":
            setItem("custom");
            selected = {
              collections: items?.collections?.filter(
                (item) => item?.collectionType === "custom"
              ),
            };
            setSelected(selected);
            break;
          default:
            setItem("items");
            setSelected({});
            break;
        }

        break;
      case "sort":
        setSelected({});
        switch (e.target.value) {
          case "high":
            setSort("high");
            selected = {
              albums: selected?.albums?.sort((a, b) =>
                parseInt(a?.price) < parseInt(b?.price)
                  ? 1
                  : parseInt(b?.price) < parseInt(a?.price)
                  ? -1
                  : 0
              ),
              songs: selected?.songs?.sort((a, b) =>
                parseInt(a?.price) < parseInt(b?.price)
                  ? 1
                  : parseInt(b?.price) < parseInt(a?.price)
                  ? -1
                  : 0
              ),
              collections: selected?.collections?.sort((a, b) =>
                parseInt(a?.price) < parseInt(b?.price)
                  ? 1
                  : parseInt(b?.price) < parseInt(a?.price)
                  ? -1
                  : 0
              ),
              royalties: selected?.royalties?.sort((a, b) =>
              parseInt(a?.price) < parseInt(b?.price)
                ? 1
                : parseInt(b?.price) < parseInt(a?.price)
                ? -1
                : 0
            ),
            };
            setSelected(selected);
            break;
          case "low":
            setSort("low");
            selected = {
              albums: selected?.albums?.sort((a, b) =>
                a.price > b.price ? 1 : b.price > a.price ? -1 : 0
              ),
              songs: selected?.songs?.sort((a, b) =>
                a.price > b.price ? 1 : b.price > a.price ? -1 : 0
              ),
              collections: selected?.collections?.sort((a, b) =>
                parseInt(a?.price) > parseInt(b?.price)
                  ? 1
                  : parseInt(b?.price) > parseInt(a?.price)
                  ? -1
                  : 0
              ),
              royalties: selected?.royalties?.sort((a, b) =>
              parseInt(a?.price) > parseInt(b?.price)
                ? 1
                : parseInt(b?.price) > parseInt(a?.price)
                ? -1
                : 0
            ),
            };
            setSelected(selected);
            break;
          default:
            setSort("sort");
            setSelected({});
            break;
        }

        break;
      default:
        setSelected(selected);
        break;
    }
  };

  const getTrendingItems = () => {
    let trending = []
    let topSongs = topDigitalPurchases?.songs?.length > 0 ? topDigitalPurchases?.songs : []
    let topAlbums = topDigitalPurchases?.albums?.length > 0 ? topDigitalPurchases?.albums : []
    let topCollections = topCollectionPurchases?.length > 0 ? topCollectionPurchases : []
    trending = shuffleSongs([...topSongs, ...topAlbums, ...topCollections])
    return shuffleSongs(trending)
  }

  return (
    <>
      <StoreContainer>
        <StoreWrapper>
        <MusicStoreHeaderContainer>
            <MusicStoreHeader>
              <Title>Music Store</Title>
            </MusicStoreHeader>
          </MusicStoreHeaderContainer>          <SearchContainer>
            <SearchBarWrapper>
              <SearchBar
                type={"search"}
                placeholder={
                  "Search for music and NFT collections available for purchase on our platform"
                }
                value={searchValue}
                onChange={handleSearch}
              />
              <SearchButton unselectable="on">
                <FaSearch />
              </SearchButton>
            </SearchBarWrapper>
          </SearchContainer>

            {searchValue.trim()?.length === 0 &&
            <StoreFiltersContainer>
              <StoreFilter>
                <FilterButton
                  disabled={Object?.keys(items)?.length === 0}
                  onChange={(e) => handleInput(e, "categories")}
                >
                  <option value={"category"}>Category</option>
                  <br />
                  <option value={"all"}>All</option>
                  <option value={"downloads"}>Digital Downloads</option>
                  <option value={"royalties"}>Regium Shares</option>
                  <option value={"nfts"}>NFT Collections</option>
                
                </FilterButton>
              </StoreFilter>
              <StoreFilter>
                <FilterButton
                  disabled={Object?.keys(items)?.length === 0}
                  onChange={(e) => handleInput(e, "items")}
                >
                  <option value={"items"}>Items</option>
                  <option value={"albums"}>Albums</option>
                  <option value={"singles"}>Singles</option>
                  <option value={"custom"}>Custom NFTs</option>
                </FilterButton>
              </StoreFilter>
              <StoreFilter>
                <FilterButton
                  disabled={Object?.keys(items)?.length === 0}
                  onChange={(e) => handleInput(e, "sort")}
                >
                  <option value={"sort"}>Sort</option>
                  <option value={"high"}>Highest Price</option>
                  <option value={"low"}>Lowest Price</option>
                </FilterButton>
              </StoreFilter>
            </StoreFiltersContainer>}
          {/*   {swerlVouchers?.length > 0 && Object.keys(selectedItems).length === 0 && (
            <ContentWrapper heading={"Subscription Vouchers"}>
              <Slider
                type={SLIDER_TYPES.SWERL_VOUCHER}
                id={"swerl vouchers"}
                slides={swerlVouchers}
              />
            </ContentWrapper>
          )} */}
          {searchValue.trim()?.length === 0 && Object.keys(selectedItems).length === 0 && (
            <>
             {searchValue.trim()?.length === 0 && collections?.collections?.length > 0 && (
                <ContentWrapper heading={"NFToken Collections"} subheading={'View All'} onClick={() => goTo('/collections')}>
                  <Slider
                    type={SLIDER_TYPES.COLLECTION}
                    id={"nft collection"}
                    onSlideClick={(i) =>
                      navigate( 
                        `collection/${collections?.collections[i].collectionId}`
                      )
                    }
                    slides={collections?.collections}
                  />
                </ContentWrapper>
              )}

             {searchValue.trim()?.length === 0 && musicRoyalties?.royalties?.length > 0 && (
                <ContentWrapper heading={"Music Regium Shares"}  subheading={'View All'} onClick={() => goTo('/regiums')} >
                  <Slider
                    type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
                    id={"music royalties"}
                   /*  onSlideClick={(i) =>
                      navigate( 
                        `collection/${collections[i].collectionId}`
                      ) 
                    }*/
                    slides={musicRoyalties?.royalties}
                  />
                </ContentWrapper>
              )}
               
              {(songs?.length > 0 || albums.length > 0) && item !== "custom" && (
                <ContentWrapper heading={"Music for Digital Purchase"}>
                  {songs?.length > 0 &&
                    (!selectedItems?.songs ||
                      Object?.keys(selectedItems)?.length === 0) && (
                      <ContentWrapper heading={"Songs"} subheading={'View All'} onClick={() => goTo('/downloadableSongs')}>
                        <Slider
                          type={SLIDER_TYPES.DIGITAL_SONG}
                          purchase={true}
                          id={"songs"}
                          slides={songs}
                        />
                      </ContentWrapper>
                    )}
                  {albums?.length > 0 && (
                    <ContentWrapper heading={"Albums"} subheading={'View All'} onClick={() => goTo('/downloadableAlbums')}>
                      <Slider
                        type={SLIDER_TYPES.DIGITAL_ALBUM}
                        purchase={true}
                        id={"albums"}
                        slides={albums}
                      />
                    </ContentWrapper>
                  )}
                </ContentWrapper>
              )}
            </>
          )}
             {
                searchValue.trim()?.length === 0 && 
                <>
                 {!isCollectionsLoaded && <ContentWrapper heading={"NFToken Collections"}>
              
                <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
            
            </ContentWrapper>}

            {!isRoyaltiesLoaded && <ContentWrapper heading={"Music Regium Shares"}>
              
              <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
          
          </ContentWrapper> }
          
            {(!isSongsLoaded || !isAlbumsLoaded) && <ContentWrapper heading={"Music for Digital Purchase"}>
                {!isSongsLoaded && <ContentWrapper heading={"Songs"}>
                  <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
                  </ContentWrapper>}
                  {!isAlbumsLoaded && <ContentWrapper heading={"Albums"}>
                  <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
                  </ContentWrapper>}
              </ContentWrapper>}
             
              {!isTrendDigitalsLoaded && <ContentWrapper heading={"Trending"}>
              
                <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
            
            </ContentWrapper>}
              
              </>
              }
            
          {searchValue.trim()?.length === 0 && selectedItems && Object.keys(selectedItems)?.length > 0 && (
            <>
              <ContentWrapper heading={"Filtered Music Store Items"}>
              {selectedItems?.collections?.length > 0 && (
                  <Slider
                    type={SLIDER_TYPES.COLLECTION}
                    id={"filtered collections"}
                    slides={selectedItems?.collections}
                  />
                )}
                     {selectedItems?.royalties?.filter(r => r?.royaltyId)?.length > 0 && (
                  <Slider
                    type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
                    id={"search royalties"}
                    slides={selectedItems?.royalties}
                  />
                )}
                {selectedItems?.songs?.length > 0 && (
                  <Slider
                    type={SLIDER_TYPES.DIGITAL_SONG}
                    id={"filtered songs"}
                    slides={selectedItems?.songs}
                  />
                )}
                {selectedItems?.albums?.length > 0 && (
                  <Slider
                    type={SLIDER_TYPES.DIGITAL_ALBUM}
                    id={"filtered albums"}
                    slides={selectedItems?.albums}
                  />
                )}
                
              </ContentWrapper>
            </>
          )}

          {(searchValue?.length > 0 && searchValue?.trim() !== 0)  && <ContentWrapper heading={`Search "${searchValue}"`}> 
          {searchItems && Object.keys(searchItems)?.length > 0 && (
            <>
            {searchItems?.collections?.filter(c => c?.collectionId)?.length > 0 && (
                  <Slider
                    type={SLIDER_TYPES.COLLECTION}
                    id={"search collections"}
                    slides={searchItems?.collections}
                  />
                )}
       
                {searchItems?.songs?.filter(s => s?.songId)?.length > 0 && (
                  <Slider
                    type={SLIDER_TYPES.DIGITAL_SONG}
                    id={"search songs"}
                    slides={searchItems?.songs}
                  />
                )}
                {searchItems?.albums?.filter(a => a?.albumId)?.length > 0 && (
                  <Slider
                    type={SLIDER_TYPES.DIGITAL_ALBUM}
                    id={"search albums"}
                    slides={searchItems?.albums}
                  />
                )}
               
                
             
            </> )}
          </ContentWrapper>
          }
          

          {searchValue.trim()?.length === 0 && (topCollectionPurchases?.length > 0 || Object?.keys(topDigitalPurchases)?.length > 0) && (
            <><ContentWrapper heading={"Trending Items"}>
              
              {topDigitalPurchases?.songs?.length > 0 && 
              <ContentWrapper heading={"Trending Songs"}>
              <Slider
                type={SLIDER_TYPES.MISCELLANEOUS}
                id={"trending songs"}
                slides={topDigitalPurchases?.songs}
              />
              
              </ContentWrapper>}
              {topDigitalPurchases?.albums?.length > 0 && <ContentWrapper heading={"Trending Albums"}>
              <Slider
                type={SLIDER_TYPES.MISCELLANEOUS}
                id={"trending albums"}
                slides={topDigitalPurchases?.albums}
              />
              
              </ContentWrapper>}
              {topCollectionPurchases?.length > 0 &&
              <ContentWrapper heading={"Trending NFToken Collections"}>
              <Slider
                type={SLIDER_TYPES.COLLECTION}
                id={"trending collections"}
                slides={topCollectionPurchases}
              />
            </ContentWrapper>}
              
            </ContentWrapper>
{/*             
            <ContentWrapper heading={"Top Sellers"}>
            <Slider type={SLIDER_TYPES.ARTIST} id={"top sellers"} />
          </ContentWrapper>

          <ContentWrapper heading={"Top Buyers"}>
            <Slider type={SLIDER_TYPES.USER} id={"top buyers"} />
          </ContentWrapper> */}
          </>
          
          )}
          {/* swerlVouchers?.length > 0 && Object.keys(selectedItems).length > 0 && (
            <ContentWrapper heading={"Subscription Vouchers"}>
              <Slider
                type={SLIDER_TYPES.SWERL_VOUCHER}
                id={"swerl vouchers"}
                slides={swerlVouchers}
              />
            </ContentWrapper>
          ) */}
        </StoreWrapper>
      </StoreContainer>
    </>
  );
};
