import React, {  useState, useRef, useEffect, useContext } from "react"
import AudioPlayer, { AudioPlayerSkeleton, AudioPreviewPlayer } from "../../AudioPlayer1";
import { useAuth } from "../../../contexts/AuthContext";
import { useSonarMusePlayer, useSonarMusePreviewer } from "../../../hooks/AudioHook";
import AudioPlayerPlaylist, { AudioPlayerVisualizer } from "../AudioPlayerPlaylist";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserQueue} from "../../../utils/dbUtils/userUtils";

export const AudioContext = React.createContext();
export const PreviewContext = React.createContext();

export function useAudio() {
    return useContext(AudioContext);
  }

export function usePreviewAudio() {
    return useContext(PreviewContext);
  }


export const AudioProvider = ({children}) => {
    const {currentUser, gravatarInfo} = useAuth()
    const [isOpen, setOpen] = useState(false)
    const [isQueueLoaded, setQueueLoaded] = useState(false)
    const location = useLocation()
    const navigate  = useNavigate()
   const url1 = new URL(window.location.href);
        const params = new URLSearchParams(url1.search);
  
    const {
     isPlaying, 
     stop,
    duration, 
    currentTime, 
    progressBar, 
    audioPlayer,
    playSong,
    addToQueue,
    playNext,
    handleToggle,
    changeRange,
    isRepeat,
    setTimeJump,
    calcTime, 
    backThirty, 
    forwardThirty,
    isShuffle,
    setPlaylist,
    playlist, 
    volume,
    removeFromQueue,
    play,
    timeTravel,
    togglePlayPause,
    setCurrentSong,
    toggleMute,
    toggleVolume,
    isMute,
    handleEnd,
    currentSong} = useSonarMusePlayer()

    const openViewer = () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        
        if(isOpen){
            window.scrollY = 0
            document.body.scrollY = 0
            params.delete("queue");
            navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
                replace:true},
              );
              setOpen(false)
        }else{
            params.set("queue", "true")
            navigate(`?${params.toString()}`,{
                replace:true},
              );
              setOpen(true)
        }
        
}

useEffect(() => {
   
    if(!params.has("queue")){
        closeViewer()
   }
   if(params.has("queue")){
        setOpen(true)
   }
    return () => {
      
    }
  })

useEffect(() => {
    if(!isQueueLoaded){
    getUserQueue(currentUser?.uid, setPlaylist,setCurrentSong, handleToggle, isQueueLoaded, setQueueLoaded)
}
}, [currentUser, isQueueLoaded])


 const closeViewer = (url) => {
    const url1 = new URL(window.location.href);
    const params = new URLSearchParams(url1.search);
    setOpen(false)    
    if(params.has("queue")){
         params.delete("queue");
         navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
            replace:true},
          )}
    }

    const handleMainPlayerEnd = () => {
        handleEnd()
    }

    const removeMusicFromQueue = (song) => {
        removeFromQueue(song)
    }

    const addMusicToQueue = (music) => {
        addToQueue(music)
    }

    const stopMainPlayer = () => {
        if(isPlaying){
            togglePlayPause()
        }
        return
    }

    const setMusicPlaylist = (music) => {
        setPlaylist(music)

    }

    const playMusic = (music) => {
        playSong(music)

    }

    const nextSong = () => {
        playNext()
    }

    const handleMusicToggle = (type, val) => {
         handleToggle(type, val)

       
    }

    const setCurrentSong1 = (val) => {
        setCurrentSong(val)
    }


   const  value = {
    isQueueLoaded,
    isOpen,
    stop,
    play,
    openViewer,
    isPlaying, 
    duration, 
    currentTime, 
    progressBar, 
    audioPlayer,
    timeTravel,
    playSong,
    handleToggle,
    changeRange,
    addToQueue,
    isRepeat,
    setTimeJump,
    calcTime, 
    backThirty, 
    forwardThirty,
    isShuffle,
    setPlaylist,
    playlist, 
    volume,
    isMute,
    togglePlayPause,
    setCurrentSong,
    currentSong,
    playNext,
    removeFromQueue,
    toggleMute,
    toggleVolume,
    closeViewer,
    stopMainPlayer,
    handleEnd
    }

    
return (
  
    <AudioContext.Provider value={value}>
       
      <div style={{display: isOpen ? "none": "block", maxWidth:"100vw"}}> {children}</div>
    {currentUser?.uid &&
    <>   
    {!isQueueLoaded &&  <AudioPlayerSkeleton/>}
     {isQueueLoaded && <AudioPlayer isOpen={isOpen} onClose={closeViewer} setCurrentSong={setCurrentSong} {...value} playlist={playlist?.filter(c => (!c?.isPreRelease || (c?.isPreRelease && c?.isPreview)) || ((!c?.isPreRelease || (c?.isPreRelease && c?.isPreview)) && (currentUser?.artistSubscriptions?.length > 0 && c?.artists.filter(artist => currentUser?.artistSubscriptions?.some(a => 
            artist?.artistId === a)).length > 0)))}/>}
     </> 
     
     }
    </AudioContext.Provider>
)
    

}

export const PreviewProvider = ({children}) => {
    const {currentUser} = useAuth()
    const {stopMainPlayer} = useAudio()
    const {
        isPreviewPlaying, 
        stop,
        previewDuration, 
       currentPreviewTime, 
       progressBar, 
       audioPlayer,
       playPreview,
       addToQueue,
       previewNext,
       handlePreviewToggle,
       changeRange,
       isRepeat,
       calcTime, 
       backThirty, 
       forwardThirty,
       isShuffle,
       setPreviewPlaylist,
       previewPlaylist, 
       volume,
       removeFromQueue,
       play,
       timeTravel,
       togglePreviewPlay,
       setCurrentPreview,
       toggleMute,
       toggleVolume,
       isMute,
       handleEnd,
       currentPreview} = useSonarMusePreviewer()
   

   
       
 
   
      const  value = {
       stop,
       play,
       isPreviewPlaying, 
       previewDuration, 
      currentPreviewTime, 
       progressBar, 
       audioPlayer,
       timeTravel,
       previewTimeTravel: timeTravel,
       playPreview,
       handlePreviewToggle,
       changeRange,
       addToQueue,
       isRepeat,
       calcTime, 
       backThirty, 
       forwardThirty,
       isShuffle,
       setPreviewPlaylist,
       previewPlaylist, 
       volume,
       isMute,
       togglePreviewPlay,
       setCurrentPreview,
       currentPreview,
       previewNext,
       removeFromQueue,
       toggleMute,
       toggleVolume,
       stopMainPlayer,
       stopPreviewPlayer: () => {
        if(isPreviewPlaying){
            stop()
        }
        return
    },
       handleEnd
       }
   
       
   return (
     
       <PreviewContext.Provider value={value}>
         <div style={{display: "flex", maxWidth:"100vw"}}> {children}</div>
       {!currentUser?.uid &&
         
       <AudioPreviewPlayer {...value}/>
         
        
        }
       </PreviewContext.Provider>
   )
       
   
   }