import React, { useContext, useState, useEffect } from "react";
import { auth } from "../components/Authorization/firebase";
import { signOut, onAuthStateChanged } from "@firebase/auth";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen/index";
import { getUserProfile, hashEmail } from "../components/GravatarAPI/index.js";
import { DEFAULT_CHANNEL, isUserPayingOut } from "../components/SonarMuseWebMonetization";
import { getObjectLength, hexToStr, strToHex } from "../utils";
import hexToRgbA from "../components/HexToRGBA";
import { Theme } from "../components/WebsiteThemeElements";
import { makeMicropayment } from "../utils/dbUtils";
import { convertToSwərlURL } from "../utils/swerlUtils.js";
export const AuthContext = React.createContext();

const axios = require("../components/Authorization/client");

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [accData, setAccData] = useState();
  const [gravatarInfo, setGravatarInfo] = useState();
  const [isEligible, setEligible] = useState(null);
  const [modalOpen, setOpen] = useState(false);
  const [nfTokens, setNFTokens] = useState([]);
  const [userLikes, setUserLikes] = useState([]);
  const [userWatchlist, setUserWatchlist] = useState([]);
  const [userPurchases, setUserPurchases] = useState([]);
  const [artistSubscriptions, setArtistSubscriptions] = useState([])
  const [userSubscription, setUserSubscription] = useState(false);
  const [userTippingFunds, setUserTippingFunds] = useState(false)
  const navigate = useNavigate();

  async function signup(email, address, photoURL) {
    return axios
      .post("/api/signup", {
        photoURL: photoURL,
        email: email,
        address: address,
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        //console.log('error', err.response.data)
        return err.response.data;
      });
  }

  async function login(email, address, photoURL) {
    return await axios
      .post("/api/login", {
        email: email,
        address: address,
        photoURL: photoURL,
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        console.error(err)
        return err.response.data;
      });
  }

  function logout() {
    signOut(auth).then((val) => {
      localStorage.clear();
      navigate("/");
    });
  }

  async function updateUser(email, eligibility) {
    return axios
      .post("/api/updateUser", {
        email: email,
        isEligible: eligibility,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  }

  const getUserSubscription = async (userId) => {
    return await axios
      .get(`/api/user/${userId}/checkUserSubscription`)
      .then((subscription) => {
        if (subscription?.data && !subscription?.data?.error) {
          setUserSubscription(subscription.data);
          setCurrentUser((prev) => {
            return { ...prev, subscription: subscription.data };
          });
          return subscription.data;
        }
        setUserSubscription(false);
        setCurrentUser((prev) => {
          return { ...prev, subscription: false };
        });
        return false;
      })
      .catch((err) => {
        if(err.message === "Network Error"){
          return err.message
        }else{
          setUserSubscription(false);
        setCurrentUser((prev) => {
          return { ...prev, subscription: false };
        });
        return false;
        }
        
      });
  };

  const getUserTippingFunds = async (userId) => {
    return await axios
      .get(`/api/user/${userId}/checkUserTippingFunds`)
      .then((tippingFunds) => {
        if (tippingFunds?.data && !tippingFunds?.data?.error) {
          setUserTippingFunds(tippingFunds.data?.tippingFunds);
          setCurrentUser((prev) => {
            return { ...prev, tippingFunds: tippingFunds.data?.tippingFunds };
          });
          return tippingFunds.data?.tippingFunds;
        }
        setUserTippingFunds(false);
        setCurrentUser((prev) => {
          return { ...prev, tippingFunds: false };
        });
        return false;
      })
      .catch((err) => {
        if(err.message === "Network Error"){
          return err.message
        }else{
          setUserTippingFunds(false);
        setCurrentUser((prev) => {
          return { ...prev, tippingFunds: false };
        });
        return false;
        }
        
      });
  };

  const getUserArtistSubscriptions = async (userId) => {
    return await axios
    .get(`/api/${userId}/getArtistSubscriptions`)
    .then((subs) => {
      if (subs?.data && !subs?.data?.error) {
        setArtistSubscriptions(subs?.data?.subscriptions?.map(a => {return a.artistId}));
        setCurrentUser((prev) => {
          return {
            ...prev,
            artistSubscriptions: subs?.data?.subscriptions?.map(a => {return a.artistId}),
          };
        });
        return subs.data?.subscriptions?.map(a => {return a.artistId})
      } else {
        return setArtistSubscriptions(false);
      }
    })
    .catch((err) => {
      console.error(err);
      return setArtistSubscriptions(false);
    });
  }

  const getGravatarInfo = async (email) => {
    setEligible(null);
    //setLoading(true)
    return await getUserProfile(email)
      .then((result) => {
        // //console.log('gravatar', result)
        if (result?.display_name && result !== false) {
          setGravatarInfo(result);
          setEligible(true);
          return true;
        } else {
          setGravatarInfo(false);
          setEligible(false);
          return false;
        }
      })
      .catch((err) => {
        //console.log('gT', false)
        setGravatarInfo(false);
        setEligible(false);
        //setLoading(false)

        return false;
      });
  };

  async function tipUser() {}

  async function getUserLikes(id) {
    return await axios
      .get(`/api/user/${id}/getLikes`)
      .then((data) => {
        if (data.data && !data.data.error) {
          setUserLikes(data.data);
          setCurrentUser((prev) => {
            return { ...prev, likes: data.data };
          });
          return data.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        return [];
      });
  }

  async function getUserWatchlist(id) {
    return await axios
      .get(`/api/user/${id}/getUserWatchlist`)
      .then((data) => {
        if (data.data && !data.data.error) {
          setUserWatchlist(data.data);
          setCurrentUser((prev) => {
            return { ...prev, watchlist: data.data };
          });
          return data.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        return false;
      });
  }

  const getUserPurchases = async (id) => {
    return axios
      .get(`/api/user/${id}/getDigitalDownloads`)
      .then((data) => {
        if (data.data !== false || data.data.error) {
          //console.log(`purchases: `,data.data)
          setUserPurchases(data.data);
          setCurrentUser((prev) => {
            return { ...prev, purchases: data.data };
          });
          return data.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  async function subscribeUser() {}

  const isLoggedIn = () => {
    if (currentUser?.uid) {
      return true;
    }
    return false;
  };

  const isArtist = async (uid) => {
      return await axios
        .get(`/api/${uid}/getArtistByUid`)
        .then((artist) => {
          if (artist?.data) {
            //setArtist(true)
            return artist.data;
          } else {
            //setArtist(false)
            return false;
          }
        })
        .catch((err) => {
          //setArtist(false)
          return false;
        });
  };

  const isLabel = async (uid) => {
      return await axios
        .get(`/api/${uid}/getLabelByUid`)
        .then((label) => {
          if (label?.data) {
            //setArtist(true)
            return label.data;
          } else {
            //setArtist(false)
            return false;
          }
        })
        .catch((err) => {
          //setArtist(false)
          return false;
        });
  };

  const isPodcaster = async (uid) => {
      return await axios
        .get(`/api/${uid}/getPodcasterByUid`)
        .then((podcaster) => {
          if (podcaster?.data) {
            //setArtist(true)
            return podcaster.data;
          } else {
            //setArtist(false)
            return false;
          }
        })
        .catch((err) => {
          //setArtist(false)
          return false;
        });
  };

  async function getUsersStats() {
    return await axios
      .get("/api/usersStats")
      .then((res) => {
        //console.log('stats data',res.data.numUsers)
        return res.data.numUsers;
      })
      .catch((err) => {
        return false;
      });
  }

  async function setPushNotifications(pushNotification) {
    setCurrentUser((prev) => {
      return {
        ...prev,
        pushNotifications: pushNotification,
      };
    });
  }

  async function getTrustlineStats() {
    return axios
      .get("/api/trustStats")
      .then((res) => {
        //console.log('stats1',res.data.tokenStats)
        if (res.data.tokenStats) {
          return res.data.tokenStats;
        } else {
          //console.log('err',res.data)
          return false;
        }
      })
      .catch((err) => {
        //console.log(err)
        return false;
      });
  }

  const getUserAddressInfo = async (uid) => {
    return await axios
      .get(`/api/userAddressInfo/${uid}`)
      .then((accInfo) => {
        if (accInfo.data && !accInfo?.data?.error) {
          setAccData(accInfo?.data?.accInfo?.account);
          setCurrentUser((prev) => {
            return {
              ...prev,
              ...accInfo?.data?.accInfo?.account,
            };
          });
          return accInfo?.data?.accInfo?.account;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getUserMusicNFTokens = async (uid) => {
    return axios
      .get(`/api/${uid}/getNFTokens1`)
      .then(async(nfts) => {
        if (nfts.data && !nfts.error) {
          let requests = []
          let nftokens = []
          for(let i = 0; i < nfts.data.length; i++){
            requests.push(axios.get(`/api/collection/${nfts.data[i]?.tokens[0]?.NFTokenID}/getNFTokenDetails`)
            .then((token) => {
              if (token?.data && !token?.data?.error) {
                return token.data;
              }
              return false
            })
            .catch((err) => {
              console.error(err);
              return false;
            }))

        }
        await Promise.all(requests).then((tokens) => {
          nftokens = tokens.map((t, index) => {
            if(!t?.error && t){
              return {
                ...t,
                ...nfts.data[index],
                nftoken:{
                 ...t.nftoken ,
                NFTokenID: t.nftoken.nft_id,
                Issuer: t.nftoken.issuer,
                URI: t.nftoken.uri,
                Taxon: t.nftoken.nft_taxon,},
              }
            }else{
              return {
                ...nfts.data[index],

              }
            }
          }).filter(t => t?.nftoken?.metadata?.mintType)
        }).catch(err => {
          nftokens = nfts.data.map(t => {
              return {
                ...t,
              }
            }).filter(t => t?.nftoken?.metadata?.mintType)
           
          })
          setNFTokens(nftokens);
          setCurrentUser((prev) => {
            return {
              ...prev,
              nfTokens: nftokens.filter(
                (nft) => nft?.nftoken?.metadata?.mintType
              ),
            };
          });
          return nftokens;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  /*  const makeMicropayment = async (type, id) => {
    let channel = hexToStr(document.querySelector(
      'meta[name="swirl"]'
    ).getAttribute("content")).split(".")
    let url = type && id ? `${strToHex(`${currentUser?.uid}.${id}.${type}`)}` : `${strToHex(`${currentUser?.uid}.${channel[1]}.${hexToStr(channel[0])}`)}`
    if (currentUser?.uid && channel.join(".") !== DEFAULT_CHANNEL) {
      return await axios
        .get(`/api/swirl/${url}`)
        .then((result) => {
          if (result) {
          }
          return;
        })
        .catch((err) => {
          console.error(err);
          return;
        })
    }
  }; */

  const unsubscribe = async () =>
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        getUserLikes(user?.uid);
        getUserWatchlist(user?.uid);
        getUserPurchases(user?.uid);
        getUserAddressInfo(user?.uid);
        getUserMusicNFTokens(user?.uid);
        getUserSubscription(user?.uid)
        getUserArtistSubscriptions(user?.uid)
        //Setup Graravatr Information
        getGravatarInfo(hashEmail(user?.email))
          .then((isFound) => {
            if (isFound) {
              updateUser(user?.email, true)
                .then((res) => {
                  if (res) {
                    setCurrentUser((prev) => {
                      return { ...prev, ...res };
                    });

                    setEligible(true);
                  }
                })
                .catch((err) => {
                  //console.log('Done :(', err)
                  setEligible(false);
                });
              setEligible(true);
            } else {
              updateUser(user.email, false)
                .then((res) => {
                  if (res) {
                    setCurrentUser((prev) => {
                      return { ...prev, ...res };
                    });

                    setEligible(false);
                  }
                })
                .catch((err) => {
                  //console.log('Done :(', err)
                  setEligible(false);
                });
            }

            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });

        return;
        //console.log('g', gravatarInfo)
      } else {
        setCurrentUser();
        setEligible(false);
        if (
          !localStorage.getItem("user") &&
          localStorage.getItem("accInfo") &&
          isEligible
        ) {
          setGravatarInfo(false);
          localStorage.removeItem("accInfo");
          setLoading(false);
          setEligible(false);
          return;
        }
        setLoading(false);
        return;
      }
    });

  useEffect(() => {
    //setClient(new Client(SERVERS.MAINNET[0], {connectionTimeout: 3600000, timeout: 3600000}))
    unsubscribe()
    return () => {};
  }, []);

  const isUserSubscribed = async () => {
    let sub 
    try {
      if (!currentUser?.uid) {
        await unsubscribe();
      }
      if (currentUser?.uid && !userSubscription) {
        sub = await getUserSubscription(currentUser?.uid);
        if( sub && sub !== "Network Error"){
          return true
        }
        if(sub === "Network Error"){
          return sub
        }
      }
      if (currentUser?.uid && userSubscription ) {
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      if(err?.message === 'Network Error'){
        return "Network Error"
      }else{
        return false
      }
    }
  };

  const isUserSubscribedToArtist = (artistId) => {
    if (currentUser?.uid && currentUser?.subscriptions?.artists?.length > 0) {
      if (currentUser?.artistSubscriptions.includes(artistId)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const isUserSubscribedToPodcaster = (podcasterId) => {
    if (
      currentUser?.uid &&
      currentUser?.subscriptions?.podcasters?.length > 0
    ) {
      if (currentUser?.subscriptions?.artists.includes(podcasterId)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const userPurchasedCollection = (nftoken) => {};

useEffect(() => {
    const interval = setInterval(() => {
      if (currentUser?.uid) {
        getUserSubscription(currentUser?.uid);
        getUserArtistSubscriptions(currentUser?.uid)
      }
    }, 300000);
    return () => {
      clearInterval(interval);
    };
  }, []);



  useEffect(() => {
    const interval = setInterval(async () => {
      if (currentUser?.uid && userSubscription) {
        let channel = hexToStr(
          document.querySelector('meta[name="swerl"]').getAttribute("content")
        ).split(".");
        let url = convertToSwərlURL(currentUser?.uid, channel);

        if (channel.join(".") !== DEFAULT_CHANNEL) {
          
          return await makeMicropayment(url)
            .then((result) => {
              if (result) {
              }
              return;
            })
            .catch((err) => {
              console.error(err);
              return;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
        }
      }
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  },[currentUser?.uid, userSubscription]);

  const value = {
    currentUser,
    signup,
    login,
    getUsersStats,
    logout,
    userLikes,
    userWatchlist,
    nfTokens,
    accData,
    updateUser,
    getGravatarInfo,
    getTrustlineStats,
    gravatarInfo,
    getUserLikes,
    getUserWatchlist,
    isEligible,
    setEligible,
    modalOpen,
    userPurchases,
    userSubscription,
    userTippingFunds,
    getUserPurchases,
    artistSubscriptions,
    setOpen,
    unsubscribe,
    setPushNotifications,
    isUserPayingOut,
    isArtist,
    isPodcaster,
    isLabel,
    isLoggedIn,
    getUserAddressInfo,
    getUserSubscription,
    getUserArtistSubscriptions,
    getUserTippingFunds,
    getUserMusicNFTokens,
    isUserSubscribedToPodcaster,
    isUserSubscribedToArtist,
    isUserSubscribed,
  };

  return (
    <AuthContext.Provider value={value}>
      <div
        style={{
          height: "100%",
          background: `linear-gradient(180deg, ${hexToRgbA(
            Theme({}).secondaryBg
          )} 0%, ${hexToRgbA(Theme({}).mainColor)}  100%)`,
        }}
      >
        {!loading && children}

        {loading && (
          <LoadingScreen
            mainColor={
              gravatarInfo?.profileBackground?.color
                ? gravatarInfo?.profileBackground?.color
                : Theme({}).mainColor
            }
          />
        )}
      </div>
    </AuthContext.Provider>
  );
}
