import React, {useState, useRef, useEffect} from "react";
import { useAuth } from "../../contexts/AuthContext";
import { ALERT_TYPES, CONTENT_TYPES, CURRENCIES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import CurrencySelector from "../CurrencySelector";
import { FormButton, FormInput1, FormLabel, FormTextArea, FormWrapper } from "../CustomForm/CustomFormElements";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import Modal from "../Modal";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";
import { Form, FormContent, FormInput } from "./TipFormElements";
import { makeMicropayment } from "../../utils/dbUtils";
import { useAlert } from "../Alert/AlertContext";
import { downloadFile, getObjectLength, getSongTitle, getUniqueListBy } from "../../utils";
import { ModalLoadingScreen } from "../LoadingScreen";
import { TipSelector } from "../MenuOptions";
import { TopUpStreamingSubscriptionModal } from "../StreamingSubscriptionModal";



const TipForm = ({displayName, address}) => {
  const {currentUser} = useAuth()

  const {toggleModal, modalContent} = useModal()
    const [isOpen, setOpen] = useState(true)
    const [currency, setCurrency] = useState(CURRENCIES.WYM)
    const [isSuccessful, setSuccess] = useState(false)
    const [tip, setTip] = useState(1)
    const [memo, setMemo] = useState()
    const [paymentBody, setPaymentBody] = useState({})
    const [paymentType, setPaymentType] = useState()
    const [paymentHeading, setPaymentHeading] = useState(`Tipping ${displayName}`)
  
  

   

      useEffect(() => {
        setOpen(true)
        return () => {}
      }, [])
      
 const formValidation = () => {
  //console.log(currency)

    if(tip > 0 && memo)
    {
      makePayment()
    }else if(tip > 0) {
      makePayment()
    }
  }

  const onSuccess = () => {
    modalContent(<>
      <OptionModal heading={`Successfully tipped ${displayName}`}>
        <HoverOption onClick={() => {toggleModal(); modalContent()}}>
          OK
        </HoverOption>

      </OptionModal>
    </>)
  }

  const onFailure = () => {
    modalContent(<>
      <OptionModal heading={`Successfully tipped ${displayName}`}>
        <HoverOption onClick={() => {makePayment()}}>
         Try again
        </HoverOption>
        <HoverOption onClick={() => {toggleModal(); modalContent()}}>
          OK
        </HoverOption>

      </OptionModal>
    </>)
  }

  const onCancel = () => {
    toggleModal()
    modalContent()
  }

  const makePayment =() => {
    
      
        setPaymentHeading(`Tipping ${displayName}`)
        modalContent(
          <QRCodeForm 
          body={{sender: currentUser?.uid ? currentUser.uid : undefined,
            receiver: address,
            value: tip,
            pathfinding: true,
            currency: currency,
            instruction: `${currentUser.uid ? currentUser.uid : `User`} is making the following transaction to tip ${displayName}`,
            memo: memo ? `Sonar Muse Tipper: ${memo}` : `Sonar Muse Tipper`
          }} 
          heading={paymentHeading} type={TRANSACTION_OPTIONS.TIP_USER}
          onSuccess={onSuccess}
          onFail={onFailure}
          cancelQr={onCancel}
          >
          </QRCodeForm>
   )
    setCurrency()
  }


    return (
        <>
            <OptionModal heading={`Tipping ${displayName}`}>
                     <> 
            <FormWrapper row ><FormLabel>Currency</FormLabel><CurrencySelector setCurrency={setCurrency}/></FormWrapper>

            <FormInput min={1} step={0.1} onChange={(e) => setTip(e.target.value)} type='number' placeholder='Tip amount' required value={tip}/>
            <FormInput  onChange={(e) => setMemo(e.target.value) } placeholder={`Send custom message to ${displayName}`} value={memo} />
            <HoverOption type="submit" to='#tip' onClick={formValidation}>Tip</HoverOption>
            <FormButton transparent={true}  to='#'
             onClick={() => {
              toggleModal()
              modalContent()
              }}>Cancel</FormButton>
            </> 
        </OptionModal>
        </>
    )


}



export const SwerlTipForm = ({type, content}) => {
  const {currentUser, getUserTippingFunds} = useAuth()
  const {addAlert} = useAlert()
  const [memo, setMemo] = useState()
    const [selectedArtist, setSelectedArtist] = useState({});
    const [artists, setArtists] = useState();
    const [isLoaded, setLoaded] = useState(false);
  const {modalContent, openModal, closeModal} = useModal()
  const [amount, setAmount] = useState(0)

   useEffect(() => {
      if (type === CONTENT_TYPES.EPISODE) {
        setArtists(content?.podcasters);
      } else {
        if(type === CONTENT_TYPES.SONG){
          let a = [];
        a.push(...content?.artists);
        if (content?.featuredArtists?.length > 0) {
          a.push(...content?.featuredArtists);
        }
  
        if (getObjectLength(content?.songCredits) > 0) {
          let sC = content?.songCredits;
          if (sC?.composers?.length > 0) {
            a.push(...sC?.composers);
          }
          if (sC?.writers?.length > 0) {
            a.push(...sC?.writers);
          }
          if (sC?.producers?.length > 0) {
            a.push(...sC?.producers);
          }
        }
        setArtists([...getUniqueListBy(a, "")]);
        }
        if(type === CONTENT_TYPES.PODCASTER){
          setSelectedArtist({
            name: content?.podcasterName,
            address: content?.address,
            podcasterId: content?.podcasterId
          });
        }
        if(type === CONTENT_TYPES.ARTIST){
          setSelectedArtist({
            name: content?.artistName,
            address: content?.address,
            artistId: content?.artistId
          });
        }
        
      }
      setLoaded(true);
  
      return () => {};
    }, [type]);

  const handleInput = (e) => {
    if(e.target.value[0] === "0"){
      setAmount(e.target.value.substring(1,e.target.value.length)); 
     }else{
       setAmount(e.target.value)
     }
     
     if(!isNaN(e.target.value) && e.target.value > 0){
       return
     }
     if(e.target.value?.length === 0){
       
       setAmount(0)
     }
     if(isNaN(e.target.value)){
     /*   err = {
         ...err,
         price: "Invalid Price. Please enter price or leave 0"
       } */
       setAmount(parseFloat(e.target.value))
       /* setErrors(err) */
       return
     }
  }

  const onSuccess = (tx) => {
    getUserTippingFunds()
    modalContent(<OptionModal heading={`Tipping ${selectedArtist?.name} successful`}>
      {tx && <HoverOption onClick={() => {
                      downloadFile(`https://xrplexplorer.com/explorer/${tx}`);
                    }}>View Transaction</HoverOption>}
      <HoverOption onClick={() => {
        modalContent()
        closeModal()
      }}>OK</HoverOption>
    </OptionModal>)
  }

  const onCancel = () => {
    modalContent()
    closeModal()
  }

  const onFailure = () => {
    modalContent(<SwerlTipForm type={type} content={content}/>)
  }

  const confirmTip = () => {
    if(parseFloat(currentUser?.tippingFunds/1000000).toFixed(2) > amount && amount >= 0.01 ){
      modalContent(<QRCodeForm
          type={TRANSACTION_OPTIONS.SWERL_TIP}
          body={{
            artistId: selectedArtist?.artistId,
            podcasterId: selectedArtist?.podcasterId,
            memo: `User is signing transaction to tip ${amount} XRP ${selectedArtist?.name}`,
            instruction: `User is signing transaction to tip ${amount} XRP ${selectedArtist?.name}`,
            amount: amount,
            message: memo?.length > 0 ? memo : undefined,
            sender: currentUser?.uid

          }}
          heading={`Tipping`} 
          onSuccess={onSuccess}
          onFail={onFailure}
          cancelQr={onCancel}
        />
      )
    }else{
      if(amount < 0.01 ){
        addAlert({title: "Invalid Tip Amount",
          type: ALERT_TYPES.WARNING,
          message: "You need to tip a value of 0.01 XRP or more",});
          return
        }else{
          if(amount > parseFloat(currentUser?.tippingFunds / 1000000).toFixed(3)){
          addAlert({title: "Low Tipping Balance",
            type: ALERT_TYPES.WARNING,
            message: 'Your Swerl tipping balance is lower than the tipping amount entered. Top Up before tipping.'});
          modalContent(<OptionModal heading={'Your Swerl tipping balance is lower than the tipping amount entered. Top Up before tipping.'} onCancel={() => modalContent(<TipSelector type={type} content={content}/>)}>
            <HoverOption onClick={() => modalContent(<TopUpStreamingSubscriptionModal onSuccess={() => {
              openModal()
              modalContent(<SwerlTipForm type={type} content={content}/>)
            }} onFail={() => {
              closeModal()
              modalContent()
            }}
            cancel={() => {closeModal()
              modalContent()}}
            />)}>Top Up Subscription</HoverOption>
          </OptionModal>)}
        }
    }
  }

  const selectArtist = (artist) => {
      if (type === CONTENT_TYPES.EPISODE) {
        setSelectedArtist({
          name: artist?.podcasterName,
          address: artist?.address,
          podcasterId: artist?.podcasterId
        });
      } else {
        setSelectedArtist({
          name: artist?.artistName,
          address: artist?.address,
          artistId: artist?.artistId
        });
      }
      
    };

  return (
    <>
    <OptionModal
        onCancel={() => {
          modalContent(<TipSelector type={type} content={content}/>)
        }}
        heading={`Swərl Tipper for ${
          getObjectLength(selectedArtist) > 0
            ? type === CONTENT_TYPES.EPISODE
              ? ` Podcaster ${selectedArtist?.name}`
              : type === CONTENT_TYPES.SONG
              ? ` Artist ${selectedArtist?.name}`:
              type === CONTENT_TYPES.PODCASTER
              ? ` Podcaster ${selectedArtist?.name}`
              : ` Artist ${selectedArtist?.name}`
            : type === CONTENT_TYPES.EPISODE
            ? ` Episode ${content?.episodeName}`
            : `Song ${getSongTitle(content)}`
        }`}
        additionalText={"In order to tip with Swərl, you need to have a minimum tipping balance of 0.01 XRP left, or top up before tipping. "}
      >
        {!isLoaded && <ModalLoadingScreen transparent={true} />}
        {isLoaded && ((type === CONTENT_TYPES.PODCASTER || type === CONTENT_TYPES.ARTIST) ||  (artists?.length > 0 && (type !== CONTENT_TYPES.PODCASTER || type !== CONTENT_TYPES.ARTIST))) && (
          <>
            {" "}
            {getObjectLength(selectedArtist) === 0 &&
              artists.map((artist) => {
                  return (
                    <>
                      <HoverOption
                        onClick={() => {
                          selectArtist(artist);
                        }}
                      >
                        {type === CONTENT_TYPES.EPISODE
                          ? artist?.podcasterName
                          : artist?.artistName}
                      </HoverOption>
                    </>
                  );
                })}
            {(type !== CONTENT_TYPES.PODCASTER || type !== CONTENT_TYPES.ARTIST) && getObjectLength(selectedArtist) > 0 && (
              <>
    
      <FormInput1 onChange={handleInput}  min={0.01}
                  step={0.01}
                  placeholder="Tip amount"
                  required
                  value={amount}/>
      <FormTextArea
                        onChange={(e) => setMemo(e.target.value)}
                        placeholder={`Send custom message to ${selectedArtist?.name}`}
                        value={memo}
                      />
     
     <HoverOption onClick={confirmTip}>Confirm Tip</HoverOption>
      </>)}
      </>
    )}
    </OptionModal>
    </>
  )
}


export const DonationForm = ({cause, displayName, address, currency}) => {
  const {currentUser} = useAuth()

  const {toggleModal, modalContent} = useModal()
    const [isOpen, setOpen] = useState(true)
    const [isSuccessful, setSuccess] = useState(false)
    const [tip, setTip] = useState(1)
    const [memo, setMemo] = useState()
    const [paymentBody, setPaymentBody] = useState({})
    const [paymentType, setPaymentType] = useState()
    const [paymentHeading, setPaymentHeading] = useState(`Making a donation to the cause ${cause}`)
  
  

   

      useEffect(() => {
        setOpen(true)
        return () => {}
      }, [])
      
 const formValidation = () => {
  //console.log(currency)

    if(tip > 0 && memo)
    {
      makePayment()
    }else if(tip > 0) {
      makePayment()
    }
  }

  const onSuccess = () => {
    modalContent(<>
      <OptionModal heading={`Successfully donated to ${cause}. ${displayName} Thanks you`}>
        <HoverOption onClick={() => {toggleModal(); modalContent()}}>
          OK
        </HoverOption>

      </OptionModal>
    </>)
  }

  const onFailure = () => {
    modalContent(<>
      <OptionModal heading={`Successfully donated to ${cause}. ${displayName} Thanks you`}>
        <HoverOption onClick={() => {makePayment()}}>
         Try again
        </HoverOption>
        <HoverOption onClick={() => {toggleModal(); modalContent()}}>
          OK
        </HoverOption>

      </OptionModal>
    </>)
  }

  const onCancel = () => {
    toggleModal()
    modalContent()
  }

  const makePayment =() => {
    
      
        setPaymentHeading(`Donating to ${cause}`)
        modalContent(
          <QRCodeForm 
          body={{sender: currentUser?.uid ? currentUser.uid : undefined,
            receiver: address,
            value: tip,
            pathfinding: true,
            currency: currency,
            instruction: `${currentUser.uid ? currentUser.uid : `User`} is making the following transaction to tip ${displayName}`,
            memo: memo ? `Sonar Muse Donation: ${memo}` : `Sonar Muse Donation`
          }} 
          heading={paymentHeading} type={TRANSACTION_OPTIONS.TIP_USER}
          onSuccess={onSuccess}
          onFail={onFailure}
          cancelQr={onCancel}
          >
          </QRCodeForm>
   )
  }


    return (
        <>
            <OptionModal heading={`Donating to ${cause}`}>
                     <> 
            <FormWrapper row ><FormLabel>Currency</FormLabel>{currency}</FormWrapper>

            <FormInput min={1} step={0.1} onChange={(e) => setTip(e.target.value)} type='number' placeholder='Tip amount' required value={tip}/>
            <HoverOption type="submit" to='#tip' onClick={formValidation}>Tip</HoverOption>
            <FormButton transparent={true}  to='#'
             onClick={() => {
              toggleModal()
              modalContent()
              }}>Cancel</FormButton>
            </> 
        </OptionModal>
        </>
    )


}

export default TipForm