import React, { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { TRANSACTION_OPTIONS } from "../../../utils/Enum";
import ContentWrapper from "../../ContentWrapper";
import { getUserProfile as getGravatarProfile, hashEmail } from "../../GravatarAPI";
import { FormButton1, FormH1, FormInput1, FormLabel, FormText, FormWrapper } from "../../CustomForm/CustomFormElements";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import Modal from "../../Modal";
import QRCodeForm from "../../QRCodeForm";
import ToggleButton from "../../ToggleButton";
import { Theme } from "../../WebsiteThemeElements";
import XRPL from "../../XRPL";
import {
  ActionButton,
  ActionButtonsContainer,
  ContentContainer,
  ErrorText,
  Input,
  InputLabel,
  ProfileNavLink,
  SettingsWrapper,
} from "./ProfileContentElements";
import { useNavigate } from "react-router-dom";
import GravatarSettings from "../../GravatarAPI/GravatarSettings";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";
import { Button1 } from "../../ButtonElement";
import OptionModal from "../../OptionModal";
import { useModal } from "../../Modal/ModalContext";

function ProfileSettings({ getUserProfile, setTab }) {
  const { currentUser, logout } = useAuth();
  const {modalContent, openModal, toggleModal, closeModal} = useModal()
  const navigate  = useNavigate()
  const [paymentPointer, setPaymentPointer] = useState(
    currentUser?.paymentPointer ? currentUser?.paymentPointer : ""
  );
  const [devnetAddress, setDevnetAddress] = useState(
    currentUser?.devnetAddress ? currentUser?.devnetAddress : ""
  );
  const [isVisible, setVisible] = useState(
    currentUser?.isVisible ? currentUser?.isVisible : false
  );
  const [errors, setErrors] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [requestUpdate, setRequestUpdate] = useState(false);
  const [hasGravatar, setHasGravatar] = useState(false)
  const [currentTab, setCurrentTab] = useState('general')
  const handleInput = (e) => {
    switch (e.target.name) {
      case "Payment Pointer":
        setPaymentPointer(e.target.value);
        break;

      case "Devnet Wallet":
        setDevnetAddress(e.target.value);
        break;


      default:
        break;
    }
  };


  useEffect(() => {
    getGravatarProfile(hashEmail(currentUser?.email)).then(profile => {
      if(profile?.display_name){
        setHasGravatar(true)
      }
    }).catch(err => {
      setHasGravatar(false)
    })
  
    return () => {
      
    }
  }, [])
  

  const updateProfile = () => {
    setUserProfile({
      sender: currentUser?.uid,
      email: currentUser?.email,
      uid: currentUser?.uid,
      profile: {
        paymentPointer: paymentPointer?.trim(),
        isVisible: isVisible,
        devnetAddress: devnetAddress?.trim(),
      },
      instruction: `Updating profile for ${currentUser?.uid.substring(
        0,
        4
      )}...${currentUser?.uid.substring(currentUser?.uid?.length - 5, currentUser?.uid?.length)}`,
    });
    setRequestUpdate(true);
  };

  const cancelUpdate = () => {
    setRequestUpdate(false);
    setErrors({ message: "update cancelled" });
  };

  const onSuccess = async () => {
    await getUserProfile();
    setRequestUpdate(false);
    setErrors({ message: "Successfully updated profile" });
    setTab("library")
  };

  const onFailure = () => {
    setRequestUpdate(false);
    setErrors({ message: "Failed to update profile" });
  };
  const deactivateAccount = () => {
    openModal()
    modalContent(<OptionModal heading={'Deactivate Account'}
    additionalText={'Are you sure you want to continue deleting your account? You will no longer have access to the platform. Your music purchases will still be accessible in the music downloader.'}>
      <HoverOption onClick={() => {
        modalContent(
        <QRCodeForm
        heading={'Deactivating Account'}
          type={TRANSACTION_OPTIONS.DEACTIVATE_USER_ACCOUNT}
          body={{
            sender: currentUser?.uid,
            instruction: `${currentUser?.uid} is signing transaction to deactivate Sonar Muse account`,
            memo: `${currentUser?.uid} is signing transaction to deactivate Sonar Muse account`,
          }}

          onSuccess={() => {
            logout()
            modalContent(<OptionModal>
              <HoverOption onClick={() => {
            closeModal()
            modalContent()
          }}>
                OK
              </HoverOption>
            </OptionModal>)
          }}
          onFail={() => {
            closeModal()
            modalContent()
          }}
          cancelQr={() => {
            closeModal()
            modalContent()
          }}
          />
        )
      }}>
        Delete My Account
      </HoverOption>
      <HoverOption>
        Cancel
      </HoverOption>
    </OptionModal>)

  };

  return (
    <>
      {requestUpdate && (
        <Modal isOpen={requestUpdate} onRequestClose={cancelUpdate}>
          <QRCodeForm
            heading={`Updating ${currentUser?.uid}'s Profile`}
            body={userProfile}
            cancelQr={cancelUpdate}
            onSuccess={onSuccess}
            onFail={onFailure}
            type={TRANSACTION_OPTIONS.UPDATE_USER_PROFILE}
          ></QRCodeForm>
        </Modal>
      )}
      <NavContainer style={{marginBottom: '5%'}}>
        <Nav>
          <NavLink active={currentTab === 'general'} onClick={() => setCurrentTab('general')}>General</NavLink>
          <NavLink active={currentTab === 'gravatar'} onClick={() => setCurrentTab('gravatar')}>Gravatar</NavLink>
        </Nav>
      </NavContainer>
      {currentTab === 'general' && <FormWrapper>
        <FormH1>General</FormH1>
        {currentUser?.beta && (
          <FormWrapper>
            <FormLabel>
              Payment Pointer (ILP) - Available for beta testers only{" "}
            </FormLabel>
            <FormInput1
              name={"Payment Pointer"}
              placeholder="Payment Pointer"
              value={paymentPointer}
              onChange={handleInput}
            />
          </FormWrapper>
        )}
        <FormWrapper>
          <SettingsWrapper><InputLabel>Devnet Wallet</InputLabel> <XRPL setAddress={setDevnetAddress} /></SettingsWrapper>
          <FormInput1
            name={"Devnet Wallet"}
            placeholder="NFT Dev Net Wallet Address"
            value={devnetAddress}
            onChange={handleInput}
          />
                  <FormText>A Dev Net wallet address to test out buying NFToken collections on our platform.</FormText>
        <FormText>This address will be used to sign NFToken transactions on the XRPL Dev Net <b>ONLY</b>.</FormText>

        </FormWrapper>

<FormWrapper>
        <SettingsWrapper>
          <InputLabel for={"toggleVisibility"}>Profile Visibility </InputLabel>
          <ToggleButton
            name="toggleVisibility"
            toggle={isVisible}
            offText={"Off"}
            onText={"On"}
            toggleClick={() => setVisible(!isVisible)}
          />
        </SettingsWrapper></FormWrapper>
        <FormWrapper>
        <SettingsWrapper>
        <InputLabel>Deactivate Account</InputLabel> <HoverOption onClick={deactivateAccount}>Delete Account</HoverOption>
      </SettingsWrapper></FormWrapper>
      </FormWrapper>}

      {currentTab === 'gravatar' && <FormWrapper>
        <FormH1>Gravatar</FormH1>
      {!hasGravatar && <FormWrapper>
        <FormText>
          Visit
          <a
            style={{ color: Theme({}).accentColor, margin: "0 0.5%" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://en.gravatar.com"
          >
            {"  Gravatar "}
          </a>{" "}
          to create and customize your profile
        </FormText>
      </FormWrapper>}
      {hasGravatar && 
       
          <GravatarSettings email={currentUser?.email} type={'User'}/>
         
      }
      
      </FormWrapper>}

      
        {errors?.message && <ErrorText>{errors.message}</ErrorText>}
        {currentUser && (
          <ActionButtonsContainer>
            <Button1 onClick={() => {
              setTab("library")
            }}>Cancel</Button1>
            <Button1 onClick={updateProfile}>Update Profile</Button1>
          </ActionButtonsContainer>
        )}
      
    </>
  );
}

export default ProfileSettings;
