import JSZip from "jszip";
import FileSaver, { saveAs } from "file-saver";
import {
  storage,
  storageRef,
  uploadBytes,
  getDownloadUrl,
} from "../components/Authorization/firebase";
import { CONTENT_TYPES } from "./Enum";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { getIPFSFile } from "./nfTokensUploads";
import { strToHex } from ".";
import axiosInstance from "../components/Authorization/client";

export const zipFiles = async (files) => {
  let fileZipper = new JSZip();
  for (let i = 0; i < files.length; i++) {
    fileZipper.file(files[i].name, new File([files[i]], files[i].name));
  }
  let zip = await fileZipper
    .generateAsync({ type: "blob" })
    .then((content) => {
      
      return content;
    }).catch(err => {
      return false
    });
    //console.log('Return: ', zip)
  return zip;
};

export const downloadFiles = async (music) => {
    const storage = getStorage();
    //console.log(music)
   return getDownloadURL(ref(storage, music?.ref))
    .then((url) => {
    // `url` is the download URL for 'images/stars.jpg'
    let blob
    // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      blob = xhr.response;
      
    };
    xhr.open('GET', url);
    xhr.send();
    const a = document.createElement('a')
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = music.displayName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    // Or inserted into an <img> element
  })
  .catch((error) => {
    // Handle any errors
    return
  });

  return 
}

export const downloadTokenGateFiles = async(fileInfo) => {
  let url = await getIPFSFile(fileInfo)
  let blob
  const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      blob = xhr.response;
      
    };
    xhr.open('GET', url);
    xhr.send();
    const a = document.createElement('a')
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = fileInfo?.name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    return
}


export const formatFileSize = function (bytes) {
  const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
};

export const getResizedImage = function (url){
  /* if(url && url?.length > 0 ){
   let newURL
  let splitUrl = url?.split(".")
  newURL = splitUrl[0]
  for(let i = 1; i < splitUrl.length; i++)
    {
      if(i === splitUrl?.length - 1){
        newURL = newURL + `_${size}x${size}.` + splitUrl[i]
      }else{
        newURL = newURL + `.` + splitUrl[i]

      }
      
    }
    return  imageExists(newURL).then((exists) => {
      console.log(exists)
      if(exists){
        return newURL
     }
     return url
    }).catch(err => {
      return url
    }) 

   
   
  
  
  console.log(strToHex(url))

} */  return axiosInstance.get(`/api/image/${strToHex(url)}/resizeImage`).then((img) => {
  if(img.data?.image){
    return img.data?.image
  }
  return url
}).catch(err => {
  //console.error(err)
  return url
}) 
}

async function imageExists(image_url){

  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
 http.send();
  console.log(http.status)
  return ((http.status !== "404") && (http.status !== 404)) && true;

}



